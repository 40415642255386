<template>
    <div class="form-group row">
        <label class="col-lg-4 col-form-label"><strong class="pull-right">{{title}}</strong></label>
        <div class="col-lg-8">
            <div class="switch">
                <div class="onoffswitch">
                    <input type="checkbox" :name="name" :checked="true" :value="value" :disabled="disabled" class="onoffswitch-checkbox" id="example1">
                    <label class="onoffswitch-label" for="example1">
                        <span class="onoffswitch-inner onoffswitch-inner-hr" ></span>
                        <span class="onoffswitch-switch"></span>
                    </label>
                </div>
            </div>
            <span class="form-text m-b-none text-danger">{{error}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SwitchComponent",
        props: [
            'title',
            'name',
            'value',
            'checked',
            'disabled',
            'error',
            'id',
            'class_append',
        ],
        mounted() {
        }
    }
</script>

<style scoped>
    .onoffswitch-inner-hr:before {
        content: "DA";
    }
    .onoffswitch-inner-hr:after {
        content: "NE";
    }
    .onoffswitch-inner-en:before {
        content: "ON";
    }
    .onoffswitch-inner-en:after {
        content: "OFF";
    }
</style>