<template>
    <div class="container" style="margin-top: 50px;" >
        <div class="vld-parent" style="margin-left: auto; margin-right: auto; ">
            <loading
                :active.sync="open_loader"
                :can-cancel="false"
                :is-full-page="true"
                :color="'red'"
            >
            </loading>
        </div>
        <div class="row" style="background-color: white; min-height: 100%; padding-bottom: 30px; padding-top: 30px">
            <div class="text-xl-left">
                <div v-if="skup" class="col-lg-12" v-html="skup.opis"></div>
                <div v-if="skup && !show_webinars" class="col-lg-12" v-html="skup.opis2"></div>
                <div v-if="skup && !show_webinars" class="col-lg-12" v-html="skup.gdpr_text"></div>
                <div v-if="skup && show_form && !show_webinars" class="col-lg-12">
                    <forms
                        v-if="clan"
                        :settings="settings"
                        @onFormButtonClick="onFormButtonClick"
                    >
                    </forms>
                </div>
                <div v-if="show_webinars" class="col-lg-12">


<!--                    <section class="under-registration" style="background-color: lightgray;">-->
<!--                        <div class="container">-->
<!--                            <div class="row" style="background-color: white;" >-->
<!--                                <div class="col-md-12" style="margin-bottom: 50px; margin-top: 50px; text-align: justify;">-->
<!--                                    <h4 class="text-danger">SVEČANO OTVORENJE SKUPA I OBJAVA DOBITNIKA KOLOSA</h4>-->
<!--                                    <br>-->
<!--                                    <ul>-->
<!--                                        <li><a class="video-links" href="https://vimeo.com/562826521/185732684b#t=52m31s" target="_blank">Svečano otvorenje i pozdravni govor (video link)</a></li>-->
<!--                                        <li>-->
<!--                                            Plenarna sjednica-->
<!--                                            <ul>-->
<!--                                                <br/>-->
<!--                                                <li>-->
<!--                                                    <a class="video-links" href="https://vimeo.com/562826521/185732684b#t=2h01m57s" target="_blank">Nacionalni plan oporavka i otpornosti 2021.-2026. - INICIJATIVA 6. Obnova zgrada  (video link)</a><br/>-->
<!--                                                    <small><b>Darko Horvat, Tomislav Mihotić, Mladen Vedriš, Damir Novotny, Mirko Habijanec, Nina Dražin Lovrec</b></small>-->
<!--                                                </li>-->
<!--                                                <br/>-->
<!--                                                <li>-->
<!--                                                    <a class="video-links" href="https://vimeo.com/562826521/185732684b#t=3h18m34s" target="_blank">Izazovi obnove od potresa (video link)</a><br/>-->
<!--                                                    <small><b>Darko Horvat, Damir Vanđelić, Gordan Hanžek, Mirjana Čagalj, Josip Atalić, Mario Todorić</b></small>-->
<!--                                                </li>-->
<!--                                            </ul>-->
<!--                                        </li>-->
<!--                                        <br>-->

<!--                                    </ul>-->
<!--                                    <ul>-->
<!--                                        <li>-->
<!--                                            <a class="video-links" href="https://vimeo.com/564137872/ca6e2563b9#t=3h35m23s" target="_blank">dodjela nagrade <b>KOLOS</b> (video link)</a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                    <br>-->


<!--                                    <h4 class="text-danger"> Ministarstvo prostornog uređenja, graditeljstva i državne imovine </h4>-->
<!--                                    <ul>-->
<!--                                        <li>-->
<!--                                            <a class="video-links" href="https://vimeo.com/564137872/ca6e2563b9#t=17m12s" target="_blank">Prostorni planovi stare i nove generacije u Informacijskom sustavu prostornog uređenja (video link)</a><br/>-->
<!--                                            <small><b>Bojan Linardić, Alen Grabar</b></small>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="video-links" href="https://vimeo.com/564137872/ca6e2563b9#t=48m38s" target="_blank">Potresi u Hrvatskoj -  procjena štete i gubitaka (video link)</a><br/>-->
<!--                                            <small><b>Krunoslav Katić, Davorin Oršanić</b></small>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="video-links" href="https://vimeo.com/564137872/ca6e2563b9#t=1h32m12s" target="_blank">Energetska i sveobuhvatna obnova zgrada (video link)</a><br/>-->
<!--                                            <small><b>Danijel Žamboki, Irena Križ Šelendić</b></small>-->
<!--                                        </li>-->
<!--                                        <li>-->
<!--                                            <a class="video-links" href="https://vimeo.com/564137872/ca6e2563b9#t=1h55m14s" target="_blank">Primjena Zakona o obnovi i Program mjera obnove zgrada oštećenih potresom na području Grada Zagreba,<br/> Krapinsko-zagorske županije, Zagrebačke županije, Sisačko -moslavačke županije i Karlovačke županije (video link)</a><br/>-->
<!--                                            <small><b>Valentina Buljubašić</b></small>-->
<!--                                        </li>-->

<!--                                    </ul>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </section>-->
                    <section class="under-registration" style="background-color: lightgray;">
                        <div class="container">
                            <div class="row" style="background-color: white;" >
                                <div class="col-md-12" style="margin-bottom: 50px; margin-top: 50px; text-align: justify;">
                                    <h4 class="text-danger">Korištenje podzemnog prostora i primjena digitalizacije</h4>
                                    <h5 class=""><i>Voditelj: Davorin Kolić</i></h5>
                                    <ul>
                                        <li><a class="video-links" href="https://vimeo.com/562829896/44d5fb652d" target="_blank">Geološke podloge Zagreba za budući metro (video link)</a><br>
                                            <small><b>Stanislav Francisković</b></small><br/>
                                            <a href="https://dani.hkig.hr/prezentacije/Geološke podloge Zagreba za budući metro sustav.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li><a class="video-links" href="https://vimeo.com/562829896/44d5fb652d#t=25m23s" target="_blank">Digital Technology in the Warszaw metro (video link)</a><br>
                                            <small><b>Marek Sokolowski</b></small><br/>
                                            <a href="https://dani.hkig.hr/prezentacije/2. KPP_Prezentacja p. Sokołowski_HKIG 2021_v1.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li><a class="video-links" href="https://vimeo.com/562829896/44d5fb652d#t=44m11s" target="_blank">Ground Conditions for Bucharest metro (video link)</a><br>
                                            <small><b>Ovidiu Arghiroiu</b></small><br/>
                                            <a href="https://dani.hkig.hr/prezentacije/Ground Conditions in Bucharest Metro.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li><a class="video-links" href="https://vimeo.com/562829896/44d5fb652d#t=1h14m21s" target="_blank">Seizmički utjecaj na projekte metroa (video link)</a><br>
                                            <small><b>Davorin Kolić</b></small><br/>
                                            <a href="https://dani.hkig.hr/prezentacije/Seizmički utjecaj na projekte metroa.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>
                                    <br>
                                    <h4 class="text-danger">Hidrotehničko inženjerstvo</h4>
                                    <h5 class=""><i>Voditelji: Danko Biondić, Željko Pavlin</i></h5>
                                    <ul>
                                        <li><a class="video-links" href="https://vimeo.com/562829896/44d5fb652d#t=3h20m56s" target="_blank">Program tehničkih smjernica u hidrologiji; smjernice za hidrološko nazivlje, simbole i jedinice (video link)</a><br>
                                            <small><b>Marijan Babić, Darko Barbalić, Damir Bekić, Tamara Brleković, Gordana Bušelić, Ivana Ivanković, Josip Rubnić, Berislav Rupčić</b></small><br/>
                                            <a href="https://dani.hkig.hr/prezentacije/Program tehničkih smjernica u hidrologiji.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li><a class="video-links" href="https://vimeo.com/562829896/44d5fb652d#t=3h40m14s" target="_blank">HES Kosinj (video link)</a><br>
                                            <small><b>Tomislav Tomić,                         Danijel Krešić</b></small><br/>
                                            <a href="https://dani.hkig.hr/prezentacije/HES Kosinj.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562829896/44d5fb652d#t=4h09m13s" target="_blank">Karte opasnosti od poplava i karte rizika od poplava (video link)</a><br>
                                            <small><b>Darko Barbalić, Sanja Barbalić, Danko Biondić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Karte opasnosti od poplava i karte rizika od poplava.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562829896/44d5fb652d#t=4h19m22s" target="_blank">Društveni aspekti poplava (video link)</a><br>
                                            <small><b>Damir Bekić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Društveni aspekti poplava.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562829896/44d5fb652d#t=4h42m14s" target="_blank">Upravljanje poplavnim rizicima u minski sumnjivim područjima (video link)</a><br>
                                            <small><b>Vlatko Kadić, Damir Bekić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Upravljanje poplavnim rizicima u minsko sumnjivim područjima.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562829896/44d5fb652d#t=5h00m30s" target="_blank">Matematički model Vranskog jezera i problem zaštite od zaslanjivanja (video link)</a><br>
                                            <small><b>Davor Stipanić, Luka Zaharija, Siniša Družeta, Vanja Travaš</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Matematički model Vranskog jezera i problem zaštite od zaslanjivanja.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>
                                    <br>
                                    <h4 class="text-danger">Održavanje građevina</h4>
                                    <h5 class=""><i>Voditelj: Jelena Bleiziffer</i></h5>
                                    <ul>
                                        <li>Digitalni blizanci podvodnih građevina<br>
                                            <small><b>Josip Rukavina
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Digitalni blizanci podvodnih građevina.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562828928/33b0a9c2e0" target="_blank">Razvoj oštećenja u periodu između glavnih pregleda nadvožnjaka i mostova (video link)</a><br>
                                            <small><b>Donka Würth, Jure Galić, Marko Žilnik</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Razvoj oštećenja u periodu između glavnih pregleda nadvožnjaka i mostova.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li><a class="video-links" href="https://vimeo.com/562828928/33b0a9c2e0#t=25m37s" target="_blank">Projekt sanacije starog Savskog mosta (video link)</a><br>
                                            <small><b>Tomislav Brozović, Igor Džajić, Petar Sesar</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Projekt sanacije Starog savskog mosta u Zagrebu.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li><a class="video-links" href="https://vimeo.com/562828928/33b0a9c2e0#t=43m59s" target="_blank">Gornji ustroj željezničke pruge - održavanje prema stanju (video link)</a><br>
                                            <small><b>Neno Kladarić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Gornji ustroj željezničke pruge održavanje prema stanju.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562828928/33b0a9c2e0#t=1h20m55s" target="_blank">Primjena georadara u ocjeni stanja građevina (video link)</a><br>
                                            <small><b>Ana Baričević, Ksenija Tešić, Marijana Serdar</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Primjena georadara u ocjeni stanja konstrukcija.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562828928/33b0a9c2e0#t=1h44m48s" target="_blank">Migrirajući inhibitori korozije - strategije, rješenja i iskustva s primjerima u zaštiti i sanaciji AB konstrukcija od korozije (video link)</a><br>
                                            <small><b>Ivana Lipoščak</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Migrirajući inhibitori korozije.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562828928/33b0a9c2e0#t=2h08m09s" target="_blank">Trajnost i projektiranje trajnosti metalnih mostova - primjeri iz njemačke mostogradnje 21. stoljeća (video link)</a><br>
                                            <small><b>Mario Paradžik</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Trajnost i projektiranje trajnosti metalnih mostova.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <!-- <li>Zakonski propisani pregledi zgrada i poveznica s prevencijom šteta od potresa<br>
                                             <small><b>Ivan Markić, Marko Markić, Igor Koprivnjak</b></small>
                                         </li>-->

                                    </ul>

                                    <h4 class="text-danger">Upravljanje projektima gradnje</h4>
                                    <h5 class=""><i>Voditelj: Krunoslav Komesar</i></h5>
                                    <br>
                                    <ul>
                                        <li><a class="video-links" href="https://vimeo.com/562828928/33b0a9c2e0#t=3h11m44s" target="_blank">Rekonstrukcija i izgradnja pruge Križevci-Koprivnica - državna granica - prikaz projekta s posebnim osvrtom na upravljanje dokumentacijom (video link)</a><br>
                                            <small><b>Branimir Bosanac</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Projekt izgradnje i rekonstrukcije pruge Križevci-državna granica - prikaz projekta.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li><a class="video-links" href="https://vimeo.com/562828928/33b0a9c2e0#t=3h39m20s" target="_blank">Organizacija izgradnje privremenog kontejnerskog naselja "Centar novog života" u Petrinji (video link)</a><br>
                                            <small><b>Krunoslav Komesar, Andrija Stipić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Organizacija brze izgradnje kontejnerskog naselja Centar novog života u Petrinji.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li><a class="video-links" href="https://vimeo.com/562828928/33b0a9c2e0#t=4h00m23s" target="_blank">Izgradnja pristupnih cesta mostu Pelješac, izgradnja dionice Duboka-Sparagovići/Zaradeže - PROBLEMATIKA GRAĐENJA I UGOVORNIH OGRANIČENJA (video link)</a><br>
                                            <small><b>Edvin Bralić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Problematika građenja i ugovornih ograničenja - dodatak.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                            <br/><a href="https://dani.hkig.hr/prezentacije/Problematika građenja i ugovornih ograničenja.pdf" target="_blank"><small><b>Prezentacija - dodatak</b></small></a>
                                        </li>

                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Potresno inženjerstvo</h4>
                                    <h5 class=""><i>Voditelji: Davor Grandić, Josip Atalić, Mario Uroš</i></h5>
                                    <ul>
                                        <li><a class="video-links" href="https://vimeo.com/562830627/b21f807cd0" target="_blank">Tipologija oštećenja nearmiranih zidanih zgrada na području Sisačko-moslavačke županije nakon potresa 29.12.2020. godine (video link)</a><br>
                                            <small><b>Jug Drobac
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Tipologija oštećenja nearmiranih zidanih zgrada na području Sisačko-moslavačke županije.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=16m18s" target="_blank">Projektna rješenja obnove potresom oštećenih zgrada (video link)</a><br>
                                            <small><b>Mario Todorić, Miroslav Duvnjak, Ivan Dragičević, Petar Todorić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Tipologija oštećenja nearmiranih zidanih zgrada na području Sisačko-moslavačke županije.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=45m34s" target="_blank">Elaborat ocjene postojećeg stranja i projekt obnove zgrade u Zagrebu koja je pod zaštitom ureda za kulturnu baštinu (video link)</a><br>
                                            <small><b>Filip Barišić, Igor Hranilović</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Elaborat ocjene postojećeg stranja i projekt obnove zgrade u Zagrebu koja je kulturno dobro.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=1h02m26s" target="_blank">Pristup konstrukcijskoj obnovi crkve Sv.Mihael u Gračanima (Zagreb)   (video link)</a><br>
                                            <small><b>Juraj Pojatina, Tamara Horvat, Mario Uroš, Maja Baniček</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Pristup konstrukcijskoj obnovi crkve Sv. Mihaela u Gračanima.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=1h16m21s" target="_blank">Proračun zidova na otkazivanje izvan ravnine (video link)</a><br>
                                            <small><b>Marija Demšić, Matea Sruk</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Proračun zidova na otkazivanje izvan ravnine.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=1h37m03s" target="_blank">Seizmička analiza suhozidanih kamenih konstrukcija kombiniranom metodom konačnih i diskretnih elemenata  (video link)</a><br>
                                            <small><b>Hrvoje Smoljanović, Ivan Balić, Željana Nikolić, Ante Munjiza</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Seizmička analiza suho zidanih kamenih konstrukcija.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=1h56m55s" target="_blank">Primjeri pojačanja tri zgrade oštećene zagrebačkim potresom (video link)</a><br>
                                            <small><b>Mario Ille, Karlo Kopljar, Dražen Aničić, Tomislav Matijević, Darina Križanac, Marta Lovrak</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Projekti obnove triju zidanih zgrada u Zagrebu.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Projekti potresne obnove</h4>
                                    <h5 class=""><i>Voditelj: Dragan Kovač</i></h5>
                                    <ul>
                                        <li><a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=2h36m57s" target="_blank">Standard projekta obnove - Projekt rušenja (video link)</a><br>
                                            <small><b>Krešimir Tarnik</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Standard projekta obnove - Projekt rušenja.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li><a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=3h05m26s" target="_blank">Standard projekta obnove - Elaborat ocjene stanja konstrukcije (video link)</a><br>
                                            <small><b>Tomislav Češljaš</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Standard projekta obnove - Elaborat ocjene stanja konstrukcije.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=3h33m09s" target="_blank">
                                                Standard projekta obnove - Projekt obnove za Razinu 2 (video link)</a><br>
                                            <small><b>Antonio Maglov</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Standard projekta obnove - Projekt obnove na razinu 2 višestambena zgrada.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=3h48m00s" target="_blank">
                                                Standard projekta obnove - Projekt obnove za Razinu 3 - OŠ. M. KRLEŽE, XVIII Gimnazija i OŠ. I.G.Kovačića (video link)</a><br>
                                            <small><b>Marin Benutić, Marko Đuran, Mario Benutić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Standard projekta obnove - Projekt obnove na razinu 3.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=4h15m43s" target="_blank">
                                                Standard projekta obnove - Projekt obnove za Razinu 3 - Gornjogradska gimnazija T. Brezovački (video link)</a><br>
                                            <small><b>Boris Baljkas, Mario Benutić, Zvonimir Sigmund</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Standard projekta obnove - Projekt obnove razina 3.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Betonske konstrukcije</h4>
                                    <h5 class=""><i>Voditelj: Predrag Presečki</i></h5>
                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=5h17m00s" target="_blank">
                                                Konstrukcija stambeno-trgovačkog kompleksa RIO Rijeka (video link)</a><br>
                                            <small><b>Danijel Repac, Saša Mitrović</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Stambeno trgovački kompleks RIO RI - Daniel Repac.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>


                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=5h46m00s" target="_blank">
                                                Konstrukcija aparthotel resorta u Savudriji (video link)</a><br>
                                            <small><b>Zlatko Belošević</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Resort Savudrija - izgradnja apart hotela - Zlatko Belošević.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>


                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=6h09m00s" target="_blank">
                                                Poslovni sportski centar Agram Club na lokaciji Radnička cesta u Zagrebu (video link)</a><br>
                                            <small><b>Ozren Sudić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Poslovno-sportski centar Agram - Ozren Sudić.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>


                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=6h36m33s" target="_blank">
                                                Konstrukcija hotela View u Postirama na Braču (video link)</a><br>
                                            <small><b>Ivana Petrović, Jurica Lipovac</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Konstrukcija hotela View Postire-Brač - Ivana Petrović.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>


                                    </ul>

                                    <br>

                                    <h4 class="text-danger"><a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=7h13m20s" target="_blank">Automatizirano armiranje elemenata u Allplanu (video link)</a></h4>
                                    <h5 class=""><i>Baldinistudio d.o.o.</i></h5>

                                    <br>

                                    <h4 class="text-danger">Metalne i spregnute konstrukcije</h4>
                                    <h5 class=""><i>Voditelj: Mladen Plišić</i></h5>
                                    <ul>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=7h27m00s" target="_blank">
                                                Postrojenje za spaljivanje gorivog dijela otpada u cementari Nexe Našice (video link)</a><br>
                                            <small><b>Darko Domičić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Postrojenje za spaljivanje gorivog dijela otpada u cementari Nexe.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=7h55m45s" target="_blank">
                                                3. faza izgradnje i proširenja proizvodnih i skladišnih kapaciteta JGL (video link)</a><br>
                                            <small><b>Saša Mitrović</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Proširenje proizvodnih i skladišnih kapaciteta Jadran galenskog laboratorija Rijeka.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Geotehničke konstrukcije</h4>
                                    <h5 class=""><i>Voditelj: Leo Matešić</i></h5>
                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=8h25m00s" target="_blank">
                                                Geotehnički izazovi na dionici autoceste VC u BiH - dionica Klopče - Donja Gračanica (video link)</a><br>
                                            <small><b>Krešimir Bolanča, Emina Ramčić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Geotehnički izazovi na dionici autoceste Vc u BiH Klopče-Donja Gračanica.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=8h38m38s" target="_blank">
                                                Pobijani piloti - primjeri primjena (video link)</a><br>
                                            <small><b>Dalibor Udovič, Boris Kereš</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Pobijani piloti - primjeri primjena.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>

                                            Temeljenje obnovljenih mostova u Ninu<br>
                                            <small><b>Ante Mlinar, Ljubo Pavić, Goran Grget</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Temeljenje obnovljenih mostova u Ninu.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=8h51m01s" target="_blank">
                                                Hotel Ambasaor, Split - zaštita iskopa građevne jame ispod razine mora (video link)</a><br>
                                            <small><b>Davorin Lovrenčić, Juraj Lovrenčić, Ana Cvitković</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Hotel Ambasador Split - zaštita iskopa građevne jame ispod razine mora.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>


                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=9h12m00s" target="_blank">
                                                Predviđanje ponašanja tunela u krškim stijenskim masama primjenom alata umjetne inteligencije (video link)</a><br>
                                            <small><b>Mario Bačić, Meho Saša Kovačević</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Predviđanje ponašanja tunela u krškim stijenskim masama primjenom alata umjetne inteligencije.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=9h30m00s" target="_blank">
                                                Mogućnost primjene novih tehnologija za izradu karata klizišta za prostorno planiranje (video link)</a><br>
                                            <small><b>Snježana Mihalić Arbanas, Sanja Bernat Gazibara</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Mogućnost primjene novih tehnologija za izradu karata klizišta za prostorno planiranje.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562830627/b21f807cd0#t=9h52m35s" target="_blank">
                                                Karte klizišta za prostorno planiranje i definiranje uvjeta za gradnju u Primorju i Istri (video link)</a><br>
                                            <small><b>Petra Jagodnik, Željko Arbanas</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Karte klizišta za prostorno planiranje i definiranje uvjeta za gradnju u Primorju i Istri.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Mostovi</h4>
                                    <h5 class=""><i>Voditelj: Zlatko Šavor</i></h5>
                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839" target="_blank">
                                                Most Pelješac - montaža čelične rasponske konstrukcije (2.dio) (video link)</a><br>
                                            <small><b>Zoran Trogrlić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Prezentacija Most Pelješac__montaža čelične rasponske konstrukcije (2. dio).pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=36m56s" target="_blank">
                                                Most preko Kupe kod Pokupja (video link)</a><br>
                                            <small><b>Veljko Prpić, Ivan Filković, Juraj Galić, Andrej Šepić, Siniša Svilar</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Most preko Kupe u Pokupju.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=1h02m00s" target="_blank">
                                                Izgradnja mosta preko rijeke Save kod Gradiške (video link)</a><br>
                                            <small><b>Marko Lovrić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Izgradnja mosta preko rijeke Save kod Gradiške.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=1h54m09s" target="_blank">
                                                Specifičnost proizvodnje i montaže čelične rasponske konstrukcije mosta Sava kod Gradiške (video link)</a><br>
                                            <small><b>Mato Martić, Anita Blažević, Franjo Matanović</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Specifičnosti proizvodnje i montaže čelične rasponske konstrukcije novog mosta preko rijeke Save kod Gradiške.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
<!--                                        <li>-->

<!--                                            Vijadukti Dumanja Jaruga 1 i 2 na Pelješkoj cesti<br>-->
<!--                                            <small><b>Damir Tkalčić, Tomislav Igrec-->
<!--                                            </b></small>-->

<!--                                        </li>-->
                                        <li>Obnova graditeljske baštine: Rekonstrukcija mostova u Ninu<br>
                                            <small><b>Berislav Borovina, Ante Mlinar, Ljubo Pavić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Obnova graditeljske baštine - Rekonstrukcija mostova u Ninu.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Kvaliteta projekta</h4>
                                    <h5 class=""><i>Voditelji: Lino Fučić, Božo Soldo, Darko Ojvan</i></h5>
                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=2h30m27s" target="_blank">
                                                Problematika troškovnika (video link)</a><br>
                                            <small><b>Dragan Rako
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Problematika troškovnika u Hrvatskoj.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=2h46m30s" target="_blank">
                                                Anketa o kvaliteti projekata - prikaz anketnih pitanja</a><br>
                                            <small><b>Darko Ojvan</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Anketa o kvaliteti projekata - prikaz anketnih pitanja.pdf" target="_blank"><small><b>Prezentacija</b></small></a>

                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=3h09m49s" target="_blank">
                                                Anketa o kvaliteti projekata - analiza anketiranih osoba</a><br>
                                                <small><b>Božo Soldo</b></small>
                                                <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Anketa o kvaliteti projekata - analiza anketiranih osoba.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            Anketa o kvaliteti projekata - rezultati ankete<br>
                                            <small><b>Lino Fučić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Anketa o kvaliteti projekata - rezultati ankete.pdf" target="_blank"><small><b>Prezentacija</b></small></a>

                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=3h38m12s" target="_blank">
                                                Building Digital Twins & Poslije Potresna obnova</a><br/>
                                                <small><b>Dalibor Radonić </b></small>
                                                <br/><a href="https://dani.hkig.hr/prezentacije/Poslije potresna obnova - Building Digital Twins.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Građevno-tehnička regulativa - građevni proizvodi i primjena autonomne regulative</h4>
                                    <h5 class=""><i>Voditelj: Lino Fučić</i></h5>
                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=4h51m26s" target="_blank">
                                                Građevni proizvodi i gradnja građevine (video link) </a><br>
                                            <small><b>Davorin Oršanić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Građevni proizvodi i gradnje građevina.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=5h28m20s" target="_blank">
                                                Tumačenje uredbe EU 305/2011 i obveze proizvođača prozora i vrata (video link)</a><br>
                                            <small><b>Miroslav Turk</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Tumačenje Uredbe EU 305-2011 i obveze proizvođača prozora i vrata.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=6h02m15s" target="_blank">
                                                Oporaba građevnog otpada i ukidanje statusa otpada (vido link)</a><br>
                                            <small><b>Vedrana Lovinčić-Milovanović</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Oporaba građevnog otpada i ukidanje statusa otpada.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=6h23m08s" target="_blank">
                                                Obračunavanje radova po FIDIC-u i Posebnim uzancama o građenju - primjeri (video link)</a><br>
                                            <small><b>Tomislav Zović</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Obračunavanje radova po FIDIC-u i Posebnim uzancama o građenju - primjeri.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=6h23m08s" target="_blank">
                                                FIDIC inženjer (video link)</a><br>
                                            <small><b>Dragan Rako</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/FIDIC inženjer - neke dileme.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            Tolerancije u građenju - zakonsko rješenje i primjena u praksi<br>
                                            <small><b>Lino Fučić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Tolerancije u građenju - zakonsko rješenje i primjena u praksi.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=7h18m30s" target="_blank">
                                                Pravilnik o standardu usluga (video link)</a><br>
                                            <small><b>Matej Mihić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Prikaz novog Pravilnika o standardu usluga projektiranja i stručnog nadzora građenja.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Željeznička infrastruktura</h4>
                                    <h5 class=""><i>Voditelj: Stjepan Lakušić</i></h5>
                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=8h08m43s" target="_blank">
                                                Izazovi tijekom izvođenje radova na modernizaciji pruge Križevci – Koprivnica – državna granica (video link)</a><br>
                                            <small><b>Marko Vajdić, Branimir Bosanac, Ivo Jurić, Božidar Rakvin
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Izazovi tijekom izvođenja radova na modernizaciji pruge KŽ-KC-državna granica.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=8h26m46s" target="_blank">
                                                Specifičnosti zaštite od buke na željezničkim prugama (video link)</a><br>
                                            <small><b>Stjepan Lakušić, Ivo Haladin, Marijan Bogut</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Specifičnosti zaštite od buke na željezničkim prugama.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=8h38m29s" target="_blank">
                                                Ključne značajke projekta rekonstrukcije i modernizacije željezničke pruge na dionici Škrljevo-Rijeka-Šapjane (video link)</a><br>
                                            <small><b>Stjepan Kralj, Frane Burazer Iličić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Ključne značajke projekta obnove i modernizacije pružne dionice Škrljevo-Rijeka-Šapjane.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562831567/ee595fc839#t=9h05m46s" target="_blank">
                                                Rekonstrukcija željezničkog mosta Ljubošina (video link)</a><br>
                                            <small><b>Andrej Igrec, Zvonko Peričin</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Rekonstrukcija željezničkog mosta Ljubošina.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Klimatske promjene i prirodne katastrofe</h4>
                                    <h5 class=""><i>Voditelj: Mihaela Zamolo</i></h5>
                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562832016/036268bf43" target="_blank">
                                                Klimatski produkti i servis kao podrška gospodarstvu (video link)</a><br>
                                            <small><b>Ivan Güttler, Melita Perčec Tadić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Klimatski produkti i servisi kao podrška gospodarstvu.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562832016/036268bf43#t=24m04s" target="_blank">
                                                Mogućnosti betonske industrije kao odgovor na klimatske promjene (video link)</a><br>
                                            <small><b>Nina Štirmer, Ivana Banjad Pečur, Ivana Carević, Sonja Cerković</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Mogućnosti betonske industrije kao odgovor na klimatske promjene.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>

                                            Klimatske promjene i hidrološki ekstremi - pojave i mjere prilagodbe za smanjenje katastrofalnih posljedica <br>
                                            <small><b>Josip Rubnić, Barbara Karleuša
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Klimatske promjene i hidrološki ekstremi.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25" target="_blank">
                                                Mjere prilagodbe klimatskim promjenama kod upravljanja Imotskim poljem (video link)</a><br>
                                            <small><b>Adrijana Vrsalović, Božena Krce Miočić, Slaven Zjalić, Nenad Buzjak, Goran Delač, Marin Šilić, Ivo Andrić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Mjere prilagodbe klimatskim promjenama kod upravljanja Imotskim poljem.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=13m50s" target="_blank">
                                                Strujanja vjetra u urbanim područjima i utjecaj na konstrukcije (video link)</a><br>
                                            <small><b>Bernardin Peroš
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Strujanja vjetra u urbanim područjima i utjecaj na konstrukcije.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=38m00s" target="_blank">
                                                Klimatske promjene i potresna aktivnost (video link)</a><br>
                                            <small><b>Mihaela Zamolo
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Klimatske promjene i potresna aktivnost.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=1h34m09s" target="_blank">
                                                Primjena IOT tehnologije u praćenju i analizi potrošnje vode unutar javnih zgrada (video link)</a><br>
                                            <small><b>Adrijana Vrsalović, Toni Perković, Petar Šolić, Ivo Andrić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Primjena IoT tehnologije u praćenju i analizi potrošnje vode unutar javnih zgrada.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=1h45m12s" target="_blank">
                                                Inovacije u razvoju održivih proizvoda (video link)</a><br>
                                            <small><b>Mario Bojić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Inovacije u razvoju održivih proizvoda.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=1h56m40s" target="_blank">
                                                Treba li graditeljstvo u Hrvatskoj niskougljične proizvode? (video link) </a><br>
                                            <small><b>Virna Višković Agušaj
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Treba li graditeljstvo u Hrvatskoj niskougljične proizvode.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=2h20m13s" target="_blank">
                                                Jeste li znali da 1/3 ukupnog otpada dolazi iz građevinskog sektora? (video link)</a><br>
                                            <small><b>Anđelka Toto-Ormuž
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Jeste li znali da otprilike 1.3 svih otpada predstavlja građevinski otpad.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=2h39m08s" target="_blank">
                                                Smanjenje negativnih klimatskih promjena u urbanim sredinama (video link)</a><br>
                                            <small><b>Snježana Turalija
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/DGNB certificiranje za smanjenje negativnih klimatskih promjena u urbanim sredinama.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=2h58m25s" target="_blank">
                                                Ranjivost obalnog područja Primorsko-goranske županije zbog klimatskih promjena (video link)</a><br>
                                            <small><b>Igor Ružić, Čedomir Benac, Andrea Tadić, Nino Krvavica, Vedrana Petrović, Gorana Ljubičić, Dado Jakupović
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Ranjivosti obalnog područja Primorsko-goranske županije zbog klimatskih promjena.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                    </ul>

                                    <br>

                                    <h4 class="text-danger">BIM pristup u graditeljstvu </h4>
                                    <h5 class=""><i>Voditelji: Martina Pavlović Cerinski, Hrvoje Šolman</i></h5>

                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=4h36m40s" target="_blank">
                                                Scan to BIM to VR (video link)</a><br>
                                            <small><b>Gianmarco Ćurčić Baldini
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Scan to BIM to VR_Gianmarco.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=4h50m40s" target="_blank">
                                                Smjernice za BIM pristup u infrastrukturnim projektima (video link)</a><br>
                                            <small><b>Igor Džajić, Dražen Galić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Smjernice za BIM pristup u infrastrukturnim projektima.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=5h07m33s" target="_blank">
                                                BIM projektiranje konstrukcija i analitički model (video link)</a><br>
                                            <small><b>Mirko Jurčević, Zvonimir Perić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/BIM projektiranje konstrukcija i analitički model - Zvonimir Perić, Mirko Jurčević.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=5h29m51s" target="_blank">
                                                BIM za izvođače - prednosti i mogućnosti BIM pristupa u fazi izvođenja (video link)</a><br>
                                            <small><b>Boris Živković</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/BIM za izvođače - Boris Živković.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=5h50m29s" target="_blank">
                                                Analiza sudara kao standard rada na BIM projektima - zašto i kako?  (video link)</a><br>
                                            <small><b>Martina Pavlović Cerinski</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Analiza kolizija kao standard rada na BIM projektima - Martina Pavlović Cerinski.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=6h15m34s" target="_blank">
                                                Razine razvijenosti elemenata (LOD) u BIM infrastrukturnim projektima - problematika i prijedlozi (video link)</a><br>
                                            <small><b>Denis Šimenić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Razine razvijenosti u BIM infrastrukturnim projektima - Denis Šimenić.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Ugovori o građenju</h4>
                                    <h5 class=""><i>Voditelj: Hrvoje Markovinović</i></h5>
                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=7h02m00s" target="_blank">
                                                Aktualnosti sudske prakse u vezi ugovora o građenju (video link)</a><br>
                                            <small><b>Vitomir Boić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Aktualnosti sudske prakse u vezi ugovora o građenju.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=7h26m15s" target="_blank">
                                                Utjecaj više sile na ugovorne odnose (video link) </a><br>
                                            <small><b>Saša Nikšić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Utjecaj više sile na ugovorne odnose.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=7h43m20s" target="_blank">
                                                Ugovorna kazna zbog zakašnjenja - do kada je potrebno izjaviti pridržaj prava (video link) </a><br>
                                            <small><b>Petar Miladin
                                            </b></small>

                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=8h18m04s" target="_blank">
                                                Autorskopravna zaštita projekta (video link)</a><br>
                                            <small><b>Igor Gliha</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Autorskopravna zaštita projekta.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=8h38m40s" target="_blank">
                                                Pravni izazovi cjelovite obnove Zagreba (video link)</a><br>
                                            <small><b>Marko Beretić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan2/Pravni izazovi cjelovite obnove Zagreba.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=9h02m27s" target="_blank">
                                                Pravo mjerodavno za ugovor o građenju (video link) </a><br>
                                            <small><b>Davor Babić
                                            </b></small>

                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564524191/2cb5d4cc25#t=9h22m18s" target="_blank">
                                                Zadužnica kao instrument osiguranja tražbina iz ugovora o građenju (video link) </a><br>
                                            <small><b>Hrvoje Markovinović</b></small>

                                        </li>

                                    </ul>

                                    <br/>

                                    <h4 class="text-danger">Drvene konstrukcije</h4>
                                    <h5 class=""><i>Voditelj: Vlatka Rajčić</i></h5>
                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562832763/24b5debfcd#t=12m03s" target="_blank">
                                                Drvene hale s kranskim stazama (video link) </a><br>
                                            <small><b>David Anđić, Karla Mustapić, Juraj Pojatina
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Drvene hale s kranskim stazama.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562832763/24b5debfcd#t=28m09s" target="_blank">
                                                Numerička analiza mehaničkog ojačanja drvenog lameliranog nosača sportske dvorane Suhopolje (video link)</a><br>
                                            <small><b>Marko Ključanin, Mario Abramović</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Numerička analiza mehaničkog ojačanja drvenom lameliranog nosača sportske dvorane Suhopolje.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562832763/24b5debfcd#t=45m59s" target="_blank">
                                                Primjena modernih oruđa pri sakupljanju, obradi i pohrani podataka ocjene stanja drvenih konstrukcija (video link)</a><br>
                                            <small><b>Jure Barbalić, Nikola Perković, Vlatka Rajčić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Primjena modernih oruđa pri sakupljanju obradi i pohradi podataka ocjene stanja drvenih konstrukcija.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562832763/24b5debfcd#t=1h17m27s" target="_blank">
                                                Korištenje BIM tehnologije u drvenim konstrukcijama (video link) </a><br>
                                            <small><b>Ines Španiček Bićanić, Mario Abramović, Valentina Hamaček, Petar Jeger
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Korištenje BIM tehnologije u drvenim konstrukcijama.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Zidane konstrukcije  </h4>
                                    <h5 class=""><i>Voditelji: Boris Trogrlić</i></h5>
                                    <ul>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562832763/24b5debfcd#t=2h02m32s" target="_blank">
                                                Analiza postojećeg stanja, sanacija i ojačanje nosive konstrukcije Nadbiskupskog dvora na Kaptolu (video link)
                                            </a>
                                            <br>
                                            <small><b>Nikola Miletić, Dražen Strunje, Ivan Kukina</b></small>
                                            <br/>
                                            <a href="https://dani.hkig.hr/prezentacije-dan3/Analiza postojećeg stanja sanacije i ojačanja nosive konstrukcije Nadbiskupskog Dvora na Kaptolu.pdf" target="_blank">
                                                <small>
                                                    <b>Prezentacija</b>
                                                </small>
                                            </a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562832763/24b5debfcd#t=2h26m00s" target="_blank">
                                                Proračunska analiza suvremenih metoda kompozitnih ojačanja zidanih konstrukcija (Mapei) (video link)
                                            </a>
                                            <br>
                                            <small><b>Igor Hranilović</b></small>
                                            <br/>
                                            <a href="https://dani.hkig.hr/prezentacije-dan3/Proračunska analiza suvremenih metoda kompozitnih ojačanja zidanih konstrukcija.pdf" target="_blank">
                                                <small><b>Prezentacija</b></small>
                                            </a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562832763/24b5debfcd#t=2h47m15s" target="_blank">
                                                Protupotresno ojačanje tipične zagrebačke donjogradske višekatnice u uvjetima očekivanih (suvlasičkih) ograničenja (video link)
                                            </a>
                                            <br>
                                            <small><b>Martina Vukelja, Berislav Medić</b></small>
                                            <br/>
                                            <a href="https://dani.hkig.hr/prezentacije-dan3/Protupotresno ojačanje tipične zagrebačke donjogradske višekatnice.pdf" target="_blank">
                                                <small><b>Prezentacija</b></small>
                                            </a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562832763/24b5debfcd#t=3h07m00s" target="_blank">
                                                Zidana ispuna a-b zgrada (Wienerberger) (video link)
                                            </a>
                                            <br>
                                            <small><b>Boris Trogrlić, Tomislav Franko</b></small>
                                            <br/>
                                            <a href="https://dani.hkig.hr/prezentacije-dan3/Zidana ispuna a-b zgrada.pdf" target="_blank">
                                                <small><b>Prezentacija</b></small>
                                            </a>
                                        </li>
                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Izazovi građevinske fizike u ostvarivanju visokih standarda tijekom gradnje i uporabe zgrada</h4>
                                    <h5 class=""><i>Voditelji: Vanja Keindl, Nada Marđetko Škoro</i></h5>
                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562833218/1149c0d585#t=18m05s" target="_blank">
                                                ZGNE2021P3 … pristup projektiranju Zgrada Gotovo Nulte Energije u trenutnim okolnostima Pandemije, Potresa i Političke napetosti… (video link)</a><br>
                                            <small><b>Zlatan Šljivić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/ZGNE2021P3.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562833218/1149c0d585#t=41m03s" target="_blank">
                                                Prema održivim i CO2 neutralnim zgradama (video link)</a><br>
                                            <small><b>Nada Marđetko Škoro</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Prema održivim i Co2 neutralnim zgradama.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562833218/1149c0d585#t=1h03m00s" target="_blank">
                                                Subjektivni parametri u očuvanju energije (video link)</a><br>
                                            <small><b>Vanja Keindl, Paula Topić, Katarina Juričić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Subjektivni parametri u očuvanju energije.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562833218/1149c0d585#t=1h22m50s" target="_blank">
                                                Akustična ugodnost prostora (video link) </a><br>
                                            <small><b>Zoran Veršić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Akustična ugodnost prostora.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562833218/1149c0d585#t=2h10m56s" target="_blank">
                                                Tehnologije za postizanje nZEB standarda (video link)</a><br>
                                            <small><b>Bojan Milovanović
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Tehnologije za postizanje NZEB standarda.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562833218/1149c0d585#t=2h45m21s" target="_blank">
                                                Razvoj sustava za praćenje parametara uvjeta u zgradi nakon energetske obnove - Smart University (video link)</a><br>
                                            <small><b>Tomislav Veliki, Iva Fakin, Damir Vuletić, Tomislav Stašić, Marino Grozdek
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Razvoj sustava za praćenje parametara uvjeta u zgradi nakon energetske obnove.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562833218/1149c0d585#t=3h04m33s" target="_blank">
                                                Smjernice ETICS na ETICS i BIM ETICS elementi (video link)</a><br>
                                            <small><b>Dario Henezi
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Smjernice ETICS na ETICS.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562833218/1149c0d585#t=3h21m59s" target="_blank">
                                                Ispitivanje stvarnih energetskih svojstava zgrada (video link)</a><br>
                                            <small><b>Martin Pavić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Ispitivanje stvarnih energetskih svojstava zgrada.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562833218/1149c0d585#t=3h45m00s" target="_blank">
                                                Mineralne vune kao savršeno rješenje za postizanje nZEB-a (video link)</a><br>
                                            <small><b>Silvio Novak
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Mineralne vune kao savršeno rješenje za postizanje standarda NZEB.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            <a class="video-links" href="https://vimeo.com/562833218/1149c0d585#t=4h10m00s" target="_blank">
                                                Koncept obnove RenovActive (video link)</a><br>
                                            <small><b>Mario Bojić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Koncept obnove RenovActive.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Problematika vještačenja u graditeljstvu</h4>
                                    <h5 class=""><i>Voditelj: Melita Bestvina</i></h5>
                                    <ul>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564927379/44a0a8745f" target="_blank">
                                                Obnova i statička sanacija zgrada u gradu Zagrebu nakon razornog potresa u ožujku 2021. god. (video link) </a><br>
                                            <small><b>Marina Karanac
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Obnova i statička sanacija zgrada u Zagrebu nakon potresa 2020..pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564927379/44a0a8745f#t=21m34s" target="_blank">
                                                Obnova i statička sanacija zgrada nakon potresa u Banja Luci, BiH (video link)</a><br>
                                            <small><b>Božidar Hudeček</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Sanacija zgrada nakon potresa u Banja Luci.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564927379/44a0a8745f#t=50m00s" target="_blank">
                                                Ekonomske koristi i modeli financiranja protupotresne obnove i Analiza i mogućnosti zaduženja kućanstava za
                                                protupotresnu obnovu oštećenih zgrada (video link)</a><br>
                                            <small><b>Branimir Grgat
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Ekonomske koristi i modeli financiranja protupotresne obnove.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564927379/44a0a8745f#t=1h18m16s" target="_blank">
                                                Analiza oštećenja strojarskih instalacija nakon potresa u Zagrebu -
                                                projektne preporuke načina rekonstrukcije i obnove (video link)</a><br>
                                            <small><b>Marko Jakobović
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Analiza oštećenja strojarskih instalacija nakon potresa u Zagrebu.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564927379/44a0a8745f#t=1h38m40s" target="_blank">
                                                Zakon o procjeni vrijednosti nekretnina i prikaz protuustavnih odredbi iz tri prijedloga za ocjenu
                                                ustavnosti sa posljedicama za vlasnike nekretnina (video link)</a><br>
                                            <small><b>Melita Bestvina
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Zakon o procjeni vrijednosti nekretnina.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>

                                    <br>

                                    <h4 class="text-danger">Ceste</h4>
                                    <h5 class=""><i>Voditelj: Sanja Dimter</i></h5>
                                    <ul>
                                        <li>
                                        <a class="video-links" href="https://vimeo.com/564927379/44a0a8745f#t=2h37m45s" target="_blank">
                                            Izgradnja obilaznice Omiša (video link)</a><br>
                                        <small><b>Josip Škorić, Goran Puž
                                        </b></small>
                                        <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Izgradnja obilaznice Omiša.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564927379/44a0a8745f#t=3h11m09s" target="_blank">
                                                Posebnosti projektiranja i izgradnje brze ceste D12 (video link)</a><br>
                                            <small><b>Nebojša Opačić, Tomislav Kraljić, Filip Stepanić</b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Posebnosti projektiranja i izgradnje brze ceste D12.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>
                                            <a class="video-links" href="https://vimeo.com/564927379/44a0a8745f#t=3h36m18s" target="_blank">
                                                Primjena betonskog kolnika na kružnom raskrižju (video link)</a><br>
                                            <small><b>Miroslav Šimun, Sandra Mihalinac
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Primjena betonskog kolnika na kružnom raskrižju.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                        <li>

                                            Rekonstrukcija križanja "Širina" u Solinu<br>
                                            <small><b>Darko Šošić, Igor Džajić, Mate Pezer, Filip Rožić
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Rekonstrukcija križanja Širina u Solinu.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>

                                        <li>
                                            Potencijal primjene pepela iz drvne biomase u održivoj gradnji cesta<br>
                                            <small><b>Sanja Dimter, Martina Zagvozda, Miroslav Šimun
                                            </b></small>
                                            <br/><a href="https://dani.hkig.hr/prezentacije-dan3/Potencijal primjene pepela iz drvne biomase u održivog gradnji cesta.pdf" target="_blank"><small><b>Prezentacija</b></small></a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </section>


                </div>

            </div>

        </div>
    </div>
</template>

<script>
import toastr from 'toastr';
import Loading from "vue-loading-overlay";
import Forms from "@/components/form/Forms";
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
export default {
    name: "Webinari15Dani",
    components: {
        Forms,
        Loading
    },
    data(){
        return {
            show_form: false,
            show_webinars: false,
            settings: {},
            errors: {},

            open_loader: false,

            data: {
                ime: '',
                prezime: '',
                oib: '',
                adresa: '',
                mjesto: '',
                postanski_broj: '',
                zupanija: '',
                izborna_jedinica: '',
                email: ''
            },

            skup_id: 50,
            forma_id: 4,

            skup: null,
            user: null,
            clan: null,
        }
    },

    methods: {

        init(){
            let vm = this
            vm.open_loader = true;

            axios( {
                method: 'GET',
                url: process.env.VUE_APP_API_URL + 'skup',
                params:{
                    filters:{
                        id:vm.skup_id
                    }
                }
            })
                .then( function( response ) {
                    vm.skup = response.data.data[0]

                    axios.get(process.env.VUE_APP_API_URL + "korisnik-auth" )
                        .then(response => {

                            vm.user = response.data

                            axios.get(process.env.VUE_APP_API_URL + "izzymember/osoba/osoba-by-oib/" + vm.user.oib )
                            //axios.get(process.env.VUE_APP_API_URL + "izzymember/osoba/osoba-by-oib/14908768096" )
                                .then(response => {

                                    let aktivan = false;

                                    vm.clan = response.data[0];

                                    if( vm.clan )
                                        vm.clan.clanovi.forEach( function( c ) {
                                            if( c.status.grupa_id === 1 || c.status.grupa_id === 2 )
                                                aktivan = true;
                                        } )

                                    if( aktivan ){

                                        axios.get( process.env.VUE_APP_API_URL + 'forma-data', {
                                            params: {
                                                filters: {
                                                    skup_id: vm.skup_id,
                                                    korisnik_id: vm.user.id
                                                }
                                            }
                                        } )
                                            .then( response => {
                                                if( response.data.data[0] ){
                                                    vm.open_loader = false
                                                    vm.show_webinars = true
                                                } else {
                                                    try {
                                                        vm.data.ime = vm.clan.ime
                                                        vm.data.prezime = vm.clan.prezime
                                                        vm.data.oib = vm.clan.oib
                                                        vm.data.email = vm.clan.email
                                                        vm.setupForm()
                                                        vm.show_form = true
                                                        vm.open_loader = false

                                                    } catch (e) {
                                                        vm.open_loader = false
                                                        console.log( e )
                                                        toastr.error( 'Vaši podatci su neispravni, javite se u komoru.' )
                                                    }
                                                }
                                            } )
                                            .catch( error => { console.log( error ) } )

                                    } else {
                                        vm.open_loader = false
                                        toastr.error( 'Niste član komore.' )
                                    }

                                })
                                .catch(( error ) => {
                                    vm.open_loader = false
                                    console.log( error )
                                })

                        })
                        .catch(( error ) => {
                            vm.open_loader = false
                            console.log( error )
                        });


                } )
                .catch( function ( error ) {
                    vm.open_loader = false
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                } )
        },

        setupForm(){
            let vm = this;

            vm.buttons = []


            let button_save = {
                id: 'save',
                title: 'Pošalji',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + 'forma-data'
                }
            }



            let buttons = []
            buttons.push( button_save )

            let korisnik_id_input = {
                name: 'korisnik_id',
                type: 'hidden',
                value: vm.user.id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }
            let skup_input = {
                name: 'skup_id',
                type: 'hidden',
                value: vm.skup_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }
            let forma_input = {
                name: 'forma_id',
                type: 'hidden',
                value: vm.forma_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }




            let ime_input = {
                name: 'ime',
                type: 'input',
                title: 'Ime',
                value: vm.data.ime,
                width: 12,
                error: vm.errors['ime'] ? vm.errors['ime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }

            let prezime_input = {
                name: 'prezime',
                type: 'input',
                title: 'Prezime',
                value: vm.data.prezime,
                width: 12,
                error: vm.errors['prezime'] ? vm.errors['prezime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }

            let oib_input = {
                name: 'oib',
                type: 'input',
                title: 'OIB',
                value: vm.data.oib,
                width: 12,
                error: vm.errors['oib'] ? vm.errors['oib'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }






            let email_input = {
                name: 'email',
                type: 'input',
                title: 'Email',
                value: vm.user.email ?? '',
                width: 12,
                error: vm.errors['email'] ? vm.errors['email'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }


            let inputs = []
            inputs.push( korisnik_id_input )
            inputs.push( skup_input )
            inputs.push( forma_input )
            inputs.push( ime_input )
            inputs.push( prezime_input )
            inputs.push( oib_input )
            inputs.push( email_input )



            /**
             * FORMA
             */
            let settings = {
                width: 12,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            if( id === 'save' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    this.show_webinars = true
                    //this.$router.push({ path: '/' } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            }

        },
    },

    mounted() {
        this.init()
    },

}
</script>

<style scoped>

</style>

<style lang="scss">
.vertical-scrollbar {
    width: 100%;
    height: calc(100vh - 200px);
    flex-grow: 1;
    article {
        padding-bottom: 15px;
    }
}
</style>
