<template>
    <prijava-forma
            :disabled="true"
            :id="id"
            :modal="false"
            :action="'show'"
    >
    </prijava-forma>
</template>

<script>
import PrijavaForma from "@/views/pages/Prijave/PrijavaForma";
export default {
    name: "PrijavaDetails",
    components: {PrijavaForma},
    data(){
        return {
            id:this.$route.params.prijava_id,
            skup_id: this.$route.params.skup_id,
        }
    },
    methods:{
    }
}
</script>

<style scoped>

</style>