<template>
    <nav class="navbar navbar-expand-lg navbar-light" style="background-color: rgba(34, 31, 32, 0.83); min-height: 150px;">


        <div class="container">
<!--            <a class="navbar-brand" @click.prevent="goto('/pocetna')"> <img src="@/img/logo/hkig.png" style="height: 70px"></a>-->

            <button class="navbar-toggler navbar-toggler-left collapsed ml-auto"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    style="background-color: white"
                    @click="open_dropdown=!open_dropdown"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div :class="{ 'collapse':open_dropdown, 'navbar-collapse': true }" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto ml-auto" >
                    <li class="nav-item active">
                        <a @click.prevent="goto('/pocetna')" class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"><img src="@/img/navigation/home.png" style="height: 50px"> <br> <strong>POČETNA</strong> </a>
                    </li>
                    <li class="nav-item active">
                        <a v-if="logged_in" @click.prevent="goto('/prijave')" class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"><img src="@/img/navigation/prijava.png" style="height: 50px"> <br> <strong>OTVORENE PRIJAVE</strong> </a>
                    </li>
                    <li v-if="logged_in" class="nav-item active">
                        <a @click.prevent="goto('/webinari')" class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"><img src="@/img/navigation/webinari.png" style="height: 50px"> <br> <strong>WEBINARI</strong> </a>
                    </li>
                    <li class="nav-item active">
                        <a :href="'doc/' + app_owner.toLowerCase() + '.pdf'" download class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"><img src="@/img/navigation/upute.png" style="height: 50px"> <br> <strong>UPUTE ZA KORIŠTENJE</strong> </a>
                    </li>
                    <li v-if="logged_in " class="nav-item active">
                        <a @click.prevent="goto('/arhiva')" class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"><img src="@/img/navigation/arhiva.png" style="height: 50px"> <br> <strong>ARHIVA WEBINARA</strong> </a>
                    </li>
                    <li v-if="logged_in " class="nav-item active">
                        <a @click.prevent="goto('/bodovi')" class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"><img src="@/img/navigation/sati.png" style="height: 50px"> <br> <strong>SSU SATI</strong> </a>
                    </li>
                    <li v-if="logged_in" class="nav-item active">
                        <a @click.prevent="logout" class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"><img src="@/img/navigation/logout.png" style="height: 50px"> <br> <strong>ODJAVA</strong> </a>
                    </li>
                </ul>
            </div>
        </div>

    </nav>
</template>

<script>
import axios from "axios";
import toastr from "toastr"
import { mapGetters, mapActions } from "vuex";
export default {
    name: "NavigationTop",
    data(){
        return {
            logged_in: false,
            app_owner: process.env.VUE_APP_APP_OWNER,
            open_dropdown:true
        }
    },
    computed: {
        ...mapGetters("auth", ["user"])
    },
    methods:{
        goto( name ){
            this.$router.push(name)
        },

        ...mapActions("auth", ["getUserData"]),

        logout() {
            let vm = this;
            axios.post(process.env.VUE_APP_API_URL + "logout", {
                email: vm.email,
                password: vm.password
            })
            .then(response => {
                if( response.data.success ){
                    vm.$store.commit("auth/setUserData", null) ;
                    localStorage.removeItem("access_token" );
                    vm.logged_in = false;
                    vm.$router.push("/login")
                } else {
                    toastr.error('Došlo je do greške')
                }
            })
            .catch( function ( error ) {
                if(error instanceof Error) {
                    console.log( 'Error: ' . error);
                } else {
                    console.log( 'Unexpected response: ' . error);
                }
            } )
        },
    },
    mounted() {
        if (localStorage.getItem("access_token")) {
            this.getUserData();
            if ( this.user )
                this.logged_in = true
        }
    },
    watch:{
        user: function(){
            if ( this.user )
                this.logged_in = true
        }
    }

}
</script>

<style scoped>

</style>
