<template>
    <div class="container" style="margin-top: 50px;" >
        <div class="vld-parent" style="margin-left: auto; margin-right: auto; ">
            <loading
                :active.sync="open_loader"
                :can-cancel="false"
                :is-full-page="true"
                :color="'red'"
            >
            </loading>
        </div>
        <div class="row" style="background-color: white; min-height: 100%; padding-bottom: 30px; padding-top: 30px">
            <div class="col-md-12 text-xl-left">
                <div v-if="skup" class="col-lg-12" v-html="skup.opis"></div>
                <forms
                    v-if="clan"
                    :settings="settings"
                    @onFormButtonClick="onFormButtonClick"
                >
                </forms>
            </div>

        </div>
    </div>
</template>

<script>
import toastr from 'toastr';
import Loading from "vue-loading-overlay";
import Forms from "@/components/form/Forms";
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
export default {
    name: "IskazPeljeskiMost",
    components: {
        Forms,
        Loading
    },
    data(){
        return {
            settings: {},
            errors: {},

            open_loader: false,

            data: {
                ime: '',
                prezime: '',
                oib: '',
                adresa: '',
                mjesto: '',
                postanski_broj: '',
                zupanija: '',
                izborna_jedinica: '',
                email: ''
            },

            skup_id: 31,
            forma_id: 3,

            skup: null,
            user: null,
            clan: null,
        }
    },

    methods: {

        init(){
            let vm = this
            vm.open_loader = true;

            axios( {
                method: 'GET',
                url: process.env.VUE_APP_API_URL + 'skup',
                params:{
                    filters:{
                        id:vm.skup_id
                    }
                }
            })
                .then( function( response ) {
                    vm.skup = response.data.data[0]

                    axios.get(process.env.VUE_APP_API_URL + "korisnik-auth" )
                        .then(response => {

                            vm.user = response.data

                            axios.get(process.env.VUE_APP_API_URL + "izzymember/osoba/osoba-by-oib/" + vm.user.oib )
                            //axios.get(process.env.VUE_APP_API_URL + "izzymember/osoba/osoba-by-oib/81074045660" )
                                .then(response => {

                                    let aktivan = false;

                                    vm.clan = response.data[0];

                                    if( vm.clan )
                                        vm.clan.clanovi.forEach( function( c ) {
                                            if( c.status.grupa_id === 1 )
                                                aktivan = true;
                                        } )

                                    if( aktivan ){

                                        try {
                                            vm.data.ime = vm.clan.ime
                                            vm.data.prezime = vm.clan.prezime
                                            vm.data.oib = vm.clan.oib
                                            vm.data.adresa = vm.clan.adresa
                                            vm.data.mjesto = vm.clan.posta.mjesto
                                            vm.data.postanski_broj = vm.clan.posta.posta
                                            vm.data.zupanija = vm.clan.posta.zupanija.naziv

                                            let tmp = null;
                                            if( vm.clan.posta.zupanija.izborne ){
                                                vm.clan.posta.zupanija.izborne.forEach( function( izborna ) {
                                                    if( [1,2,4,5,6,7].includes( izborna.id ) )
                                                        tmp = izborna.naziv
                                                } )
                                            } else {
                                                vm.open_loader = false
                                                toastr.error( 'Vaši podatci su neispravni, javite se u komoru.' )
                                                return
                                            }

                                            if( !tmp ){
                                                vm.open_loader = false
                                                toastr.error( 'Prijave za PO Rijeka su već završene.' )
                                                return
                                            }

                                            vm.data.izborna_jedinica = tmp
                                            vm.data.email = vm.clan.oib

                                            vm.setupForm()

                                            vm.open_loader = false

                                        } catch (e) {
                                            vm.open_loader = false
                                            console.log( e )
                                            toastr.error( 'Vaši podatci su neispravni, javite se u komoru.' )
                                        }

                                    } else {
                                        vm.open_loader = false
                                        toastr.error( 'Niste aktivan član komore.' )
                                    }

                                })
                                .catch(( error ) => {
                                    vm.open_loader = false
                                    console.log( error )
                                })

                        })
                        .catch(( error ) => {
                            vm.open_loader = false
                            console.log( error )
                        });


                } )
                .catch( function ( error ) {
                    vm.open_loader = false
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                } )
        },

        setupForm(){
            let vm = this;

            vm.buttons = []


            let button_save = {
                id: 'save',
                title: 'Pošalji',
                type: 'danger',
                action: 'request',
                config: {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + 'forma-data'
                }
            }



            let buttons = []
            buttons.push( button_save )

            let korisnik_id_input = {
                name: 'korisnik_id',
                type: 'hidden',
                value: vm.user.id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }
            let skup_input = {
                name: 'skup_id',
                type: 'hidden',
                value: vm.skup_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }
            let forma_input = {
                name: 'forma_id',
                type: 'hidden',
                value: vm.forma_id,
                disabled: vm.disabled,
                config: {
                    hidden:true
                }
            }




            let ime_input = {
                name: 'ime',
                type: 'input',
                title: 'Ime',
                value: vm.data.ime,
                width: 12,
                error: vm.errors['ime'] ? vm.errors['ime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }

            let prezime_input = {
                name: 'prezime',
                type: 'input',
                title: 'Prezime',
                value: vm.data.prezime,
                width: 12,
                error: vm.errors['prezime'] ? vm.errors['prezime'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }

            let oib_input = {
                name: 'oib',
                type: 'input',
                title: 'OIB',
                value: vm.data.oib,
                width: 12,
                error: vm.errors['oib'] ? vm.errors['oib'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }

            let adresa_input = {
                name: 'adresa',
                type: 'input',
                title: 'Adresa',
                value: vm.data.adresa,
                width: 12,
                error: vm.errors['adresa'] ? vm.errors['adresa'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }

            let mjesto_input = {
                name: 'mjesto',
                type: 'input',
                title: 'Mjesto',
                value: vm.data.mjesto,
                width: 12,
                error: vm.errors['mjesto'] ? vm.errors['mjesto'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }

            let postanski_broj_input = {
                name: 'postanski_broj',
                type: 'input',
                title: 'Poštanski broj',
                value: vm.data.postanski_broj,
                width: 12,
                error: vm.errors['postanski_broj'] ? vm.errors['postanski_broj'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }

            let zupanija_input = {
                name: 'zupanija',
                type: 'input',
                title: 'Županija',
                value: vm.data.zupanija,
                width: 12,
                error: vm.errors['zupanija'] ? vm.errors['zupanija'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }

            let izborna_jedinica_input = {
                name: 'izborna_jedinica',
                type: 'input',
                title: 'Iuborna jedinica',
                value: vm.data.izborna_jedinica,
                width: 12,
                error: vm.errors['izborna_jedinica'] ? vm.errors['izborna_jedinica'] : '',
                note: '',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: true,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }

            let email_input = {
                name: 'email',
                type: 'input',
                title: 'Email',
                value: vm.user.email ?? '',
                width: 12,
                error: vm.errors['email'] ? vm.errors['email'] : '',
                note: 'Na navedenu e-mail adresu primat ćete sve obavijesti o događaju',
                explanation: '',
                placeholder: '',
                required: !vm.disabled,
                readonly: vm.disabled,
                disabled: false,
                config: {
                    title_width: 3,
                    input_width: 6
                }
            }








            let inputs = []
            inputs.push( korisnik_id_input )
            inputs.push( skup_input )
            inputs.push( forma_input )
            inputs.push( ime_input )
            inputs.push( prezime_input )
            inputs.push( oib_input )
            inputs.push( adresa_input )
            inputs.push( mjesto_input )
            inputs.push( postanski_broj_input )
            inputs.push( zupanija_input )
            inputs.push( izborna_jedinica_input )
            inputs.push( email_input )



            /**
             * FORMA
             */
            let settings = {
                width: 9,
                modal: false,
                tabs:[
                    {
                        name: '',
                        active: true,
                        sections: [
                            {
                                name: '',
                                width: 12,
                                inputs: inputs,
                            }
                        ],
                    },
                ],
                buttons: buttons
            }

            vm.settings = settings
        },

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config, data  ){
            if( id === 'save' ){
                if( data.success){
                    if( id == 'save' )
                        toastr.success( 'Uspješno spremljeno!' )
                    this.$router.push({ path: '/' } )
                } else {
                    this.errors = data.messages
                    this.init()
                }
            }

        },
    },

    mounted() {
        this.init()
    },

}
</script>

<style scoped>

</style>

<style lang="scss">
.vertical-scrollbar {
    width: 100%;
    height: calc(100vh - 200px);
    flex-grow: 1;
    article {
        padding-bottom: 15px;
    }
}
</style>