<template>
    <div class="container" style="margin-top: 50px;">
        <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
            <loading
                    :active.sync="open_loader"
                    :can-cancel="false"
                    :is-full-page="true"
                    :color="'red'"
            >
            </loading>
        </div>
            <div class="row" style="background-color: rgba(34, 31, 32, 0.83);">
                <!-- loader -->
                <div
                        v-for="(skup, index ) in skupovi"
                        :key="index" class="col-md-12 text-white text-xl-left border-bottom border-bottom-0"
                        style="margin-top:0px;"
                >
                    <div class="col-md-12" style="margin-bottom: 50px; margin-top: 50px">
                        <a @click.prevent="prijava(skup)">
                            <div class=""
                                 v-html="'<a style=\'cursor:pointer;\'>' + skup.opis + '</a>'"
                                 style="width: 100%; height: 100%;;"
                            >
                            </div>
                        </a>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import toastr from "toastr";
import moment from "moment";
export default {
name: "Prijave",
    components: {
        Loading,
    },
    data(){
        return {
            open_loader: false,
            skupovi: []
        }
    },
    methods:{
        init(){
            let vm=this
            vm.open_loader=true
            axios.get( process.env.VUE_APP_API_URL + 'skup', {
                params: {

                }
            } )
            .then( function ( response ) {
                console.log( response.data.data )
                vm.skupovi = response.data.data
                vm.open_loader = false;
            } )
            .catch( function ( error ) {
                vm.open_loader = false;
                if(error instanceof Error) {
                    console.log( 'Error: ' . error);
                } else {
                    console.log( 'Unexpected response: ' . error);
                }
            } )
        },
        prijava(  skup ){
            let vm = this
            vm.open_loader = false

            if( skup.vrsta_skupova && skup.vrsta_skupova.prijava_forma ){
                axios( {
                    method: 'GET',
                    url: process.env.VUE_APP_API_URL + 'prijava',
                    params: {
                        filters: {
                            skup_id: skup.id
                        }
                    }
                } )
                    .then( function( response ) {
                        vm.open_loader = false
                        if( response.data.results === 0 ){
                            vm.$router.push( 'prijave/' + skup.id + '/prijava')
                        } else {

                            let date1=moment( skup.datum_od, 'DD.MM.YYYY. hh:mm' )
                            let date2=moment( skup.datum_prijave_do, 'DD.MM.YYYY. hh:mm' )

                            if( date1.isBefore( date2) ){
                                toastr.error( 'Već ste prijavljeni. Nije moguće uređivati prijavu' )
                            } else {
                                vm.$router.push( 'prijave/' + skup.id + '/prijava/' + response.data.data[ response.data.data.length - 1 ].id )
                            }
                        }
                    } )
                    .catch( function ( response ) {
                        vm.open_loader = false
                        console.log( response )
                    } )
            } else if ( skup.vrsta_skupova && skup.vrsta_skupova.prilagodjena_forma ){

                if( process.env.VUE_APP_APP_OWNER === 'HKIG' && skup.id == '49'){
                    vm.$router.push( '/pravni-portal');
                }

                if( process.env.VUE_APP_APP_OWNER === 'HKIG' && skup.id == '50'){
                    vm.$router.push( '/webinari-15-dani');
                }
            } else if ( skup.vrsta_skupova && skup.vrsta_skupova.vanjska_poveznica ){
                window.location.href = JSON.parse( skup.poveznica.data )['redirect_to']
            }


        }
    },
    mounted() {
        this.init()
        this.interval = setInterval(() => this.init(), 500000);
    }
}
</script>

<style scoped>

</style>
