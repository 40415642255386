<template>
    <input
        :name="name"
        v-model="ret_value"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        type="hidden"
        class="form-control"
        @input="_return"
    />
</template>


<script>
    export default {
        name: "HiddenComponent",
        props: [
            'name',
            'value',
            'title',
            'error',
            'note',
            'explanation',
            'placeholder',
            'required',
            'readonly',
            'disabled',
            'config'
        ],
        data(){
            return {
                ret_value: this.value,
                disabled_input: this.disabled,
                title_width: ('col-form-label col-lg-' + ( this.config.title_width ? this.config.title_width : '4' ) ),
                input_width: ('col-lg-' + ( this.config.input_width ? this.config.input_width : '8' ) )
            }
        },
        methods:{
            _return() {
                this.$emit('onInput', this.name, this.ret_value );
            }
        },
        mounted() {
            this.ret_value = this.value
            this._return()
        },
        watch: {
            value(){
                this.ret_value = this.value
                this._return()
            }
        }
    }
</script>

<style scoped>

</style>