<template>
    <div>
        <div class="container">
            <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
                <loading
                        :active.sync="open_loader"
                        :can-cancel="false"
                        :is-full-page="true"
                        :color="'red'"
                >
                </loading>
            </div>
            <div v-if="!success" class="row">
                <div class=" col-lg-6">
                    <div class="container" style="margin-top: 50px; color: white">

                        <div class="row" style="background-color: rgba(34, 31, 32, 0.83);">
                            <div class="col-lg-12" style="margin: 50px; max-width: 80%">
                                <p class="h3" style="text-align: center;">
                                    <img src="@/img/navigation/login.png" style="width: 100px"> <br><br> <strong>POTVRDA REGISTRACIJE</strong>
                                    <br><br>
                                </p>

                                <h3 class="text-danger">Neispravan token!</h3>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
name: "Verify",
    components: {
    Loading
    },
    data: function() {
        return {
            success: true,
            open_loader: false,
        }
    },
    methods: {
        verify(){
            let vm = this;
            vm.open_loader = true;
            axios.post(process.env.VUE_APP_API_URL + "verify", {
                token: this.$route.params.token
            })
                .then(response => {
                    if( response.data.success ){
                        vm.$router.push('/login')
                    } else {
                        vm.success = false
                    }
                    vm.open_loader = false
                })
                .catch( function ( error ) {
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                    vm.open_loader = false
                } )
        }
    },
    mounted() {
        this.verify();
    }
}
</script>

<style scoped>

</style>
