<template>
    <div>
        <div class="container">
            <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
                <loading
                        :active.sync="open_loader"
                        :can-cancel="false"
                        :is-full-page="true"
                        :color="'red'"
                >
                </loading>
            </div>
            <div class="row">
                <div class=" col-md-6">
                    <div class="container" style="margin-top: 50px; color: white">

                        <div class="row" style="background-color: rgba(34, 31, 32, 0.83);">
                            <div class="col-md-12" style="margin: 50px; max-width: 80%">
                                <p class="h3" style="text-align: center;">
                                    <img src="@/img/navigation/login.png" style="width: 100px"> <br><br> <strong>NOVA LOZINKA</strong>
                                    <br><br>
                                </p>
                                <p>
                                    Kreirajte novu lozinku za prijavu u sustav.
                                    <br><br>
                                </p>

                                <form class="form-horizontal">

                                    <div :class="{'form-group': true}">

                                        <label
                                                for="password"
                                                class="control-label"
                                        >
                                            Upišite proizvoljnu lozinku*
                                        </label>

                                        <input
                                                id="password"
                                                type="password"
                                                class="form-control"
                                                name="password"
                                                v-model="password"
                                                required
                                                style="color: black"
                                        >

                                        <span
                                                v-if="errors && errors.password"
                                                class="help-block text-danger"
                                        >
                                                <strong>
                                                    {{errors.password[0] }}
                                                </strong>
                                            </span>

                                    </div>

                                    <div :class="{'form-group': true}">

                                        <label
                                                for="password_confirm"
                                                class="control-label"
                                        >
                                            Ponovite lozinku*
                                        </label>

                                        <input
                                                id="password_confirm"
                                                type="password"
                                                class="form-control"
                                                name="password_confirm"
                                                v-model="password_confirm"
                                                required
                                                style="color: black;"
                                        >

                                        <span
                                                v-if="errors && errors.password_confirm"
                                                class="help-block text-danger"
                                        >
                                                <strong>
                                                    {{errors.password_confirm[0] }}
                                                </strong>
                                            </span>

                                    </div>


                                    <div class="form-group">
                                        <div class="col-md-12" style="text-align: center">
                                            <button
                                                    type="submit"
                                                    class="btn btn-xl btn-secondary"
                                                    @click.prevent="reset"
                                                    style="width: 80%"
                                            >
                                                <string>Promjeni</string>
                                            </button>


                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
name: "ResetPassword",
    components: {
    Loading
    },
    data: function() {
        return {
            password: null,
            password_confirm: null,
            errors: null,
            open_loader: false,
        }
    },
    methods:{
        reset(){
            let vm = this;
            vm.open_loader = true;
            axios.post(process.env.VUE_APP_API_URL + "reset-password", {
                password: vm.password,
                password_confirm: vm.password_confirm,
                token: this.$route.params.token
            })
                    .then(response => {
                        if( response.data.success ){
                            vm.$router.push('/login')
                        } else {
                            vm.errors = response.data.messages
                        }
                        vm.open_loader = false
                    })
                    .catch( function ( error ) {
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                        vm.open_loader = false
                    } )
        }
    }
}
</script>

<style scoped>

</style>
