import axios from "axios";

export default {
  namespaced: true,

  state: {
    userData: null
  },

  getters: {
    user: state => state.userData
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user;
    }
  },

  actions: {
    getUserData({ commit }) {
      axios
          .get(process.env.VUE_APP_API_URL + "korisnik-auth" )
          .then(response => {
            commit("setUserData", response.data);
          })
          .catch(() => {
            localStorage.removeItem("access_token");
          });
    },
  }
};
