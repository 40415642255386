var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('label',{class:_vm.title_width,attrs:{"for":_vm.name}},[_c('strong',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.title)+" "+_vm._s(_vm.required ? '*' : ''))])]),(!_vm.readonly || !_vm.disabled)?_c('div',{class:_vm.input_width},[_c('span',{staticClass:"form-text m-b-none text-success"},[_vm._v(" "+_vm._s(_vm.note)+" ")]),_c('div',{staticClass:"input-group-append date"},[_c('v-date-picker',{staticStyle:{"width":"100%"},attrs:{"locale":"hr","icon":"calendar","mode":_vm.config.mode ? _vm.config.mode : 'date',"show-day-popover":false,"popover":{ visibility: 'click' },"is24hr":"","masks":{
                        inputDateTime24hr:"DD.MM.YYYY. HH:mm",
                        inputTime24hr:"HH:mm",
                        input:"DD.MM.YYYY.",
                    }},on:{"input":_vm._return},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var inputValue = ref.inputValue;
                    var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"input-group"},[_c('input',_vm._g({staticClass:"form-control",domProps:{"value":inputValue}},inputEvents)),_c('span',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-sm btn-dark",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.unset($event)}}},[_vm._v("x ")])]),(_vm.explanation)?_c('span',{staticClass:"input-group-addon",attrs:{"data-toggle":"tooltip","data-placement":"right","title":_vm.explanation}},[_c('i',{staticClass:"fa fa-info",attrs:{"aria-hidden":"true"}})]):_vm._e()])]}}],null,false,3445699581),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1),_c('span',{staticClass:"form-text m-b-none text-danger"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_vm._v(" "+_vm._s(_vm.error[0])+" ")])])])]):_c('div',{class:_vm.input_width},[_c('input',{staticClass:"form-control",attrs:{"name":_vm.name,"readonly":_vm.readonly,"disabled":_vm.disabled},domProps:{"value":_vm.value}})])])}
var staticRenderFns = []

export { render, staticRenderFns }