<template>
    <div class="container" style="margin-top: 50px;" >
        <div class="vld-parent" style="margin-left: auto; margin-right: auto; ">
            <loading
                :active.sync="open_loader"
                :can-cancel="false"
                :is-full-page="true"
                :color="'red'"
            >
            </loading>
        </div>


        <div v-if="osoba" class="row" style="background-color: white; min-height: 100%; padding-bottom: 30px; padding-top: 30px">


            <div class="col-md-12 text-xl-left">

                <h1 style="text-align: center">
                    STRUČNO USAVRŠAVANJE ČLANA
                </h1>

                <div v-if="osoba">
                    <p>
                        <span>Ime i prezime:</span>
                        <span><strong> {{ osoba.ime + ' ' + osoba.prezime }}</strong></span>
                        <br>
                        <span>OIB:</span>
                        <span><strong> {{ osoba.oib }} </strong></span>
                        <br>
                        <span>Razdoblje:</span>
                        <span><strong> {{ osoba.osoba_aktualno_razdoblje ? ( osoba.osoba_aktualno_razdoblje.datum_od + ' - ' + osoba.osoba_aktualno_razdoblje.datum_do ) : '' }} </strong></span>
                    </p>
                </div>

            </div>


            <div v-if="stavke.length" class="col-md-12 text-xl-left table-responsive">
                <table class="table table-striped ">
                    <thead class="thead-dark">
                    <tr>
                        <th scope="col">Datum</th>
                        <th scope="col">Status</th>
                        <th scope="col">Naziv skupa</th>
                        <th scope="col" style="width: 10%">Sati</th>
                        <th scope="col" style="width: 10%">Sati regulativa</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="stavka in stavke" :key="stavka.id">
                        <td>{{ stavka.datum }}</td>
                        <td>{{ stavka.vrsta_sudionika.naziv }}</td>
                        <td>{{ stavka.skup.naziv }}</td>
                        <td>{{ stavka.sati }}</td>
                        <td>{{ stavka.sati_regulativa }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>


            <div v-if="dodatne_aktivnosti.length" class="col-md-12 text-xl-left table-responsive" style="width: 100%">
                <table class="table table-striped " style="width: 100%">
                    <thead class="thead-dark" style="width: 100%">
                    <tr>
                        <th scope="col">Datum od</th>
                        <th scope="col">Datum do</th>
                        <th scope="col">Opis</th>
                        <th scope="col" style="width: 10%">Sati</th>
                        <th scope="col" style="width: 10%">Sati regulativa</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="dodatna_aktivnost in dodatne_aktivnosti" :key="dodatna_aktivnost.id">
                        <td>{{ dodatna_aktivnost.datum_od }}</td>
                        <td>{{ dodatna_aktivnost.datum_do }}</td>
                        <td>{{ dodatna_aktivnost.opis }}</td>
                        <td>{{ dodatna_aktivnost.sati }}</td>
                        <td>{{ dodatna_aktivnost.sati_regulativa }}</td>

                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-md-12 text-xl-left table-responsive" style="width: 100%">
                <table class="table table-striped " style="width: 100%">
                    <thead class="thead-dark" style="width: 100%">
                    <tr>
                        <th scope="col" ></th>
                        <th scope="col" style="width: 10%">Sati</th>
                        <th scope="col" style="width: 10%">Sati regulativa</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><strong><h4>Ukupno</h4></strong></td>
                        <td>{{ sati }}</td>
                        <td>{{ sati_regulativa }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import moment from "moment/moment";
export default {
    name: "BodoviComponent",
    components: {
        Loading
    },
    data(){
        return{
            open_loader: false,

            osoba: {},
            stavke: [],
            dodatne_aktivnosti: [],
        }
    },
    computed: {
        sati(){
            let vm = this

            let sati = 0

            vm.stavke.forEach( stavka => {
                sati += stavka.sati
            } )
            vm.dodatne_aktivnosti.forEach( dodatna_aktivnost => {
                sati += dodatna_aktivnost.sati
            } )

            return sati
        },
        sati_regulativa(){
            let vm = this

            let sati_regulativa = 0

            vm.stavke.forEach( stavka => {
                sati_regulativa += stavka.sati_regulativa
            } )
            vm.dodatne_aktivnosti.forEach( dodatna_aktivnost => {
                sati_regulativa += dodatna_aktivnost.sati_regulativa
            } )

            return sati_regulativa
        }
    },
    methods:{
        async fetchRazdoblja(){

            let vm=this
            vm.open_loader=true



            const osoba = await axios.get( process.env.VUE_APP_API_URL + 'izzyedu/osoba', {} )
            vm.osoba = osoba.data.data[osoba.data.data.length - 1 ]

            if( !vm.osoba.osoba_aktualno_razdoblje )
                return

            let razdoblje_od = vm.osoba.osoba_aktualno_razdoblje.datum_od ? moment( vm.osoba.osoba_aktualno_razdoblje.datum_od, 'DD.MM.YYYY.' ) : null
            let razdoblje_do = vm.osoba.osoba_aktualno_razdoblje.datum_do ? moment( vm.osoba.osoba_aktualno_razdoblje.datum_do, 'DD.MM.YYYY.' ) : null

            if( !razdoblje_od ){
                razdoblje_od = razdoblje_do.clone()
                razdoblje_od.add( '2', 'years' )
            }

            if( !razdoblje_do ){
                razdoblje_do = razdoblje_od.clone()
                razdoblje_do.subtract( '2', 'years' )
            }


            const stavke = await axios.get( process.env.VUE_APP_API_URL + 'izzyedu/stavka', {
                params:{
                    filters:{
                        skup_datum_i_vrijeme_od: {
                            from: razdoblje_od.format( 'DD.MM.YYYY' ),
                            to:razdoblje_do.format( 'DD.MM.YYYY' ),
                        },
                    }
                }
            } )
            const dodatne_aktivnosti = await axios.get( process.env.VUE_APP_API_URL + 'izzyedu/osoba-dodatna-aktivnost', {
                params: {
                    filters:{
                        datum_od: {
                            from: razdoblje_od.format( 'DD.MM.YYYY' ),
                            to:razdoblje_do.format( 'DD.MM.YYYY' ),
                        },
                    }
                }
            } )

            vm.stavke = stavke.data.data
            vm.dodatne_aktivnosti = dodatne_aktivnosti.data.data

            vm.open_loader=false

        }
    },
    async mounted() {
        await this.fetchRazdoblja()
    }

}
</script>

<style scoped>

</style>
