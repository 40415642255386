<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'App',
    head: {
        title: {
            inner: process.env.VUE_APP_APP_OWNER
        }
    },
    comments: { },
    computed: {},
    mounted() {},

    methods: {},
    created() {
    },
};
</script>

<style src="./styles/bootstrap.css" lang="css" />
<style src="./styles/animate.css" lang="css" />
<style src="./styles/style.css" lang="css" />
<style src="./fonts/font-awesome/css/fontawesome-all.css" lang="css" />
<style src="../node_modules/toastr/build/toastr.css" lang="css" />

<style>
</style>
