<template>
    <div>
        <div class="container">
            <div class="row">
                <div class=" col-md-6">
                    <div class="container" style="margin-top: 50px; color: white">
                        <div class="row" style="background-color: rgba(34, 31, 32, 0.83);">
                            <div class="col-md-12" style="margin: 50px; max-width: 80%">
                                <p class="h3" style="text-align: center;">
                                    <img src="@/img/navigation/login.png" style="width: 100px"> <br><br> <strong>REGISTRACIJA</strong>
                                    <br><br>
                                </p>

                                <h4 class="text-danger">Uspješno ste kreirali korisnički račun. Molimo da ga potvrdite putem poveznice koja je poslana na vaš E-mail.</h4>
                                    <br><br>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Register",
    components: {

    },
    data: function() {
        return {

        }
    },
}
</script>

<style scoped>

</style>
