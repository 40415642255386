<template>
    <prijava-forma
            :disabled="false"
            :id="null"
            :modal="false"
            :action="'create'"
    >
    </prijava-forma>
</template>

<script>
import PrijavaForma from "@/views/pages/Prijave/PrijavaForma";
export default {
    name: "PrijavaCreate",
    components: {PrijavaForma},
    data(){
        return {
            skup_id: this.$route.params.skup_id
        }
    },
    methods:{
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>