<template>
    <div class="container" style="margin-top: 50px;">

        <div class="row" style="background-color: rgba(34, 31, 32, 0.83); min-height: 100%;">
            <div class="col-md-2"></div>
            <div class="col-md-4" style="margin-top: 50px; margin-bottom: 50px">
                <a @click.prevent="goto('/prijave')" class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"><img src="@/img/navigation/prijava.png" style="width: 80%"> <br> <strong>OTVORENE PRIJAVE</strong> </a>
            </div>
            <div class="col-md-4" style="margin-top: 50px; margin-bottom: 50px">
                <a @click.prevent="goto('/webinari')" class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"><img src="@/img/navigation/webinari.png" style="width: 80%"> <br> <strong>WEBINARI</strong> </a>
            </div>
            <div class="col-md-2"></div>


            <div class="col-md-2"></div>
            <div class="col-md-4" style="margin-top: 50px; margin-bottom: 50px">
                <a href="doc/hkig.pdf" download class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"><img src="@/img/navigation/upute.png" style="width: 80%"> <br> <strong>UPUTE ZA KORIŠTENJE</strong> </a>
            </div>
            <div class="col-md-4" style="margin-top: 50px; margin-bottom: 50px">
                <a @click.prevent="goto('/arhiva')" class="nav-link btn" style="color: white; margin-left: 20px; margin-right: 20px"><img src="@/img/navigation/arhiva.png" style="width: 80%"> <br> <strong>ARHIVA WEBINARA</strong> </a>
            </div>
            <div class="col-md-2"></div>
        </div>

    </div>
</template>

<script>

export default {
    name: "Pocetna",
    components: {
    },
    methods:{
        goto( name ){
            this.$router.push(name)
        }
    },
}
</script>

<style scoped>

</style>
