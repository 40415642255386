<template>
    <div class="form-group row">
        <label class="col-lg-4 col-form-label"><strong class="pull-right">{{title}}</strong></label>


        <div class="col-md-8 border-dark" v-cloak @drop.prevent="addFile" @dragover.prevent>
            <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    @vdropzone-complete="afterComplete"
                    @vdropzone-success="afterSuccess"
                    @vdropzone-canceled="afterCanceled"
            ></vue-dropzone>
            <span class="form-text m-b-none text-danger">{{ error }}</span>

            <ul class="">
<!--                <li v-for="file in files">-->
<!--                    {{ file.label }}-->
<!--                    <button class="btn btn-danger btn-xs" @click.prevent="deleteFile( file.name )">X</button>-->
<!--                    &lt;!&ndash;<button class="btn btn_info" @click.prevent="downloadFile( file.name )">Download</button>&ndash;&gt;-->
<!--                    <div class="hr-line-dashed"></div>-->
<!--                </li>-->
            </ul>
        </div>

    </div>
</template>

<script>

    import axios from "axios";

    import toastr from "toastr";

    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        name: "UploadComponent",
        components: {
            vueDropzone: vue2Dropzone
        },
        props: [
            'type',
            'title',
            'name',
            'value',
            'disabled',
            'config',
            'error',
            'id',
            'class_append',
        ],
        computed: {
            uploadDisabled() {
                return this.files.length === 0;
            }
        },
        data(){
            return {
                dropzoneOptions: {
                    paramName: this._props.name,
                    url: this._props.config.upload_url,
                    thumbnailWidth: 150,
                    thumbnailHeight: 20,
                    maxFilesize: 5,
                    headers: { "My-Awesome-Header": "header value" }
                },
                files: {},
            }
        },
        methods:{
            afterComplete( ){
                this.$refs.myVueDropzone.removeAllFiles();
            },
            afterSuccess( file, response ){
                this.notify_s( response.message )
                this.files = response.files
                this.ret_value()
            },
            afterCanceled(){
                console.log('canceled')
            },
            deleteFile( name ){
                let vm = this;
                axios.post( vm._props.config.delete_url, {
                    'name': name
                } ).then( function( response ) {
                    vm.files = response.data;
                    vm.ret_value();
                } ).catch(function(res) {
                    if(res instanceof Error) {
                        vm.notify_e( 'Error: ' + res);
                    } else {
                        vm.notify_e( 'Unexpected response: ' + res);
                    }
                });
            },
            downloadFile( name ){
                console.log( name );
            },
            ret_value(){
                let tmp = [];
                for( let i in this.files )
                    tmp.push( this.files[i].name )
                this.$emit('return_input',{
                    name:this._props.name,
                    value: tmp
                })
            },

            /**
             *  notifications
             */
            notify_s( message = ''){
                toastr.success(message);
            },
            notify_w( message = ''){
                toastr.warning(message)
            },
            notify_e( message = '' ){
                toastr.error(message)
            },
        },
        mounted() {
            let vm = this;
            vm.files = vm._props.value;
            this.ret_value()
            vm.dropzoneOptions.url = vm._props.config.url;
        }
    }
</script>

<style scoped>

</style>