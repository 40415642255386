<template>
    <div class="container" style="margin-top: 50px;">
        <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
            <loading
                    :active.sync="open_loader"
                    :can-cancel="false"
                    :is-full-page="true"
                    :color="'red'"
            >
            </loading>
        </div>

            <div class="row" style="background-color: rgba(34, 31, 32, 0.83); min-height: 100%;">
                <!-- loader -->
                <div v-for="(prijava, index ) in prijave" :key="index" class="col-md-12 text-white text-xl-left">
                    <div v-for="(webinar, index2) in prijava.skupovi.webinars" :key="index2">

                            <div class="row border-bottom border-bottom-0">
                                <div
                                    class="col-md-3"
                                    @click.prevent="play(prijave[index], webinar )"
                                    style="cursor: pointer"
                                >
                                    <br>
                                    <div class="align-middle"  style="text-align: center;">
                                        <img class="" src="@/img/buttons/play.png" style="height: 40px; width: 40px">
                                        <br><strong>POKRENI</strong>
                                    </div>
                                </div>
                                <br>
                                <div class="col-md-9">
                                    <h2>{{ prijava.skupovi.naziv }}</h2>
                                    <h3 v-if="webinar.skupovi_teme && webinar.skupovi_teme.naziv != prijava.skupovi.naziv">{{ webinar.skupovi_teme.naziv }}</h3>
                                    <h3 v-if="webinar.naziv != prijava.skupovi.naziv">{{ webinar.naziv }}</h3>
                                    <h3>{{ webinar.datum_od }}</h3>
                                    <p v-if="webinar.opis != prijava.skupovi.naziv" v-html="webinar.opis"></p>
                                </div>
                            </div>
                    </div>

                    <br>
                    <br>
                </div>
            </div>

    </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import toastr from 'toastr';

export default {
    name: "Arhiva",
    components: {
        Loading,
    },
    data(){
        return {
            open_loader: false,
            prijave: []
        }
    },
    methods:{
        init(){
            let vm=this
            vm.open_loader=true
            axios.get( process.env.VUE_APP_API_URL + 'prijava', {
                params: {
                    options: {
                        korisnik_arhiva: true
                    }
                }
            } )
                    .then( function ( response ) {
                        vm.prijave = response.data.data
                        vm.open_loader = false;
                    } )
                    .catch( function ( error ) {
                        vm.open_loader = false;
                        if(error instanceof Error) {
                            console.log( 'Error: ' . error);
                        } else {
                            console.log( 'Unexpected response: ' . error);
                        }
                    } )
        },

        play( prijava, webinar ){
            if( webinar.url ){
                axios( {
                    method: 'POST',
                    url: process.env.VUE_APP_API_URL + 'webinar-sudionik',
                    data: {
                        webinar_id: webinar.id,
                        prijava_id: prijava.id
                    }
                } )
                        .then( function( response ) {
                            if( response.data.success ){
                                window.open(webinar.url, "_blank");
                            } else {
                                toastr.error( 'Došlo je do greške' )
                            }
                        } )
                        .catch( function ( response ) {
                            console.log( response )
                        } )
            } else {
                toastr.erroe( 'Pokušajte kasnije' )
            }
        }

    },
    mounted() {
        this.init()
        this.interval = setInterval(() => this.init(), 500000);
    }
}
</script>

<style scoped>

</style>
