<template>
    <div class="form-group row">
        <label class="col-lg-4 col-form-label"><strong class="pull-right">{{title}}</strong></label>
        <div v-if="type == 'vertical'" class="col-lg-8">
<!--            <div v-for="radio in radios" class="i-checks">-->
<!--                <label class="checkbox-inline">-->
<!--                    <div class="iradio_square-green " style="position: relative;">-->
<!--                        <input type="radio"  :value="radio.value" :name="radio.name" :checked="radio.checked" :disabled="radio.disabled" style="position: absolute; opacity: 0;">-->
<!--                        <ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins>-->
<!--                    </div>-->
<!--                    {{ radio.title }}-->
<!--                </label>-->
<!--            </div>-->
            <span class="form-text m-b-none error">{{error}}</span>
        </div>
        <div v-if="type == 'horizontal'" class="col-lg-8">
            <div class="i-checks">
<!--                <label v-for="radio in radios" class="checkbox-inline">-->
<!--                    <div class="iradio_square-green " style="position: relative;">-->
<!--                        <input type="radio"  :value="radio.value" :name="radio.name" :checked="radio.checked" :disabled="radio.disabled" style="position: absolute; opacity: 0;">-->
<!--                        <ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins>-->
<!--                    </div>-->
<!--                    {{ radio.title }}-->
<!--                </label>-->
            </div>
            <span class="form-text m-b-none error">{{error}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RadioComponent",
        props: [
            'type',
            'title',
            'radios',
            'error',
            'id',
            'class_append',
        ],
        mounted() {
            ('.i-checks').iCheck({
                radioClass: 'iradio_square-green'
            });
        }
    }
</script>

<style scoped>

</style>