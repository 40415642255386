<template>
    <div class="float-right">
        <button
                v-for="(btn, key) in buttons"
                :key="key"
                :class="btn.type"
                style="margin: 2px"
                @click.prevent="onFormButtonClick( btn.id, btn.action, btn.config )"
        >
            {{ btn.title }}
        </button>
    </div>
</template>

<script>
export default {
    name: "Buttons",
    props: [
            'buttons'
    ],
    data(){
        return {
            list: []
        }
    },
    methods:{

        /**
         *  on button click
         */
        onFormButtonClick( id, action, config ){
            this.$emit( 'onFormButtonClick', id, action, config )
        }

    },
    mounted() {
        this.list = this.buttons
    }
}
</script>

<style scoped>

</style>