<template>
    <div>
        <div class="container">
            <div class="vld-parent" style="margin-left: auto; margin-right: auto;">
                <loading
                        :active.sync="open_loader"
                        :can-cancel="false"
                        :is-full-page="true"
                        :color="'red'"
                >
                </loading>
            </div>
            <div class="row">
                <div class=" col-lg-6">
                    <div class="container" style="margin-top: 50px; color: white">

                            <div class="row" style="background-color: rgba(34, 31, 32, 0.83);">
                                <div class="col-lg-12" style="margin: 50px; max-width: 80%">
                                    <p class="h3" style="text-align: center;">
                                        <img src="@/img/navigation/login.png" style="width: 100px"> <br><br> <strong>PRIJAVA</strong>
                                        <br><br>
                                    </p>
                                    <p>
                                        Unesite Vaš email i Vašu lozinku da bi se prijavili u sustav za stručno usavršavanje.
                                        <br><br>
                                    </p>

                                    <form class="form-horizontal">

                                        <div :class="{'form-group': true}">

                                            <label
                                                    for="email"
                                                    class="control-label"
                                            >
                                                E-Mail *
                                            </label>

                                                <input
                                                        id="email"
                                                        type="text"
                                                        class="form-control"
                                                        name="email"
                                                        v-model="email"
                                                        required
                                                        autofocus
                                                        style="color: black"
                                                >

                                                <span
                                                        v-if="errors && errors.email"
                                                        class="help-block text-danger"
                                                >
                                                    <strong>
                                                        {{ errors.email[0] }}
                                                    </strong>
                                                </span>

                                            </div>


                                        <div :class="{'form-group': true}">

                                            <label
                                                    for="password"
                                                    class="control-label"
                                            >
                                                Lozinka *
                                            </label>

                                                <input
                                                        id="password"
                                                        type="password"
                                                        class="form-control"
                                                        name="password"
                                                        v-model="password"
                                                        required
                                                        style="color: black;"
                                                >

                                                <span
                                                        v-if="errors && errors.password"
                                                        class="help-block text-danger"
                                                >
                                                    <strong>
                                                        {{errors.password[0] }}
                                                    </strong>
                                                </span>

                                        </div>
                                        <p style="color: red; text-align: right">
                                            <a role="button" @click="forgotPassword">
                                                Zaboravili ste lozinku?
                                            </a>
                                        </p>


                                        <div class="form-group">
                                            <div class="col-lg-12" style="text-align: center">
                                                <button
                                                        type="submit"
                                                        class="btn btn-xl btn-secondary"
                                                        @click.prevent="login"
                                                        style="width: 80%"
                                                >
                                                    <strong>Prijavi se</strong>
                                                </button>


                                            </div>
                                        </div>
                                    </form>


                                    <p style="color: red; text-align: center">
                                        <a role="button" @click="register">
                                            Ili kreirajte korisnički račun
                                        </a>
                                    </p>
                                </div>
                            </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import axios from "axios";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
name: "Login",
    components: {
    Loading
    },
    data: function() {
        return {
            email: null,
            password: null,
            errors: null,
            open_loader: false,
        }
    },

    methods:{
        ...mapMutations( "auth", ["setUserData"] ),

        login(){
            let vm = this;
            vm.open_loader = true;
            axios.post(process.env.VUE_APP_API_URL + "login", {
                email: vm.email,
                password: vm.password
            })
                .then(response => {
                    if( response.data.success ){
                        vm.$store.commit("auth/setUserData", response.data.data.user);
                        localStorage.setItem("access_token", response.data.data.access_token);
                        vm.$router.push('/')
                    } else {
                        vm.errors = response.data.messages
                    }
                    vm.open_loader = false
                })
                .catch( function ( error ) {
                    if(error instanceof Error) {
                        console.log( 'Error: ' . error);
                    } else {
                        console.log( 'Unexpected response: ' . error);
                    }
                    vm.open_loader = false
                } )
        },

        forgotPassword(){
            this.$router.push('forgot-password')
        },

        register(){
            this.$router.push('register')
        }
    },

    mounted(){

    },

}
</script>

<style scoped>

</style>

