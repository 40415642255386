var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tabs-container"},[_c('ul',{staticClass:"nav nav-tabs",attrs:{"role":"tablist"}},_vm._l((_vm.tabs),function(val,key){return _c('li',{key:key},[(_vm.tabs.length > 1)?_c('a',{class:{
                            'nav-link':true,
                            'active': key === _vm.active_tab
                        },attrs:{"id":'tab-' + key},on:{"click":function($event){$event.preventDefault();return _vm.change( key )}}},[_vm._v(" "+_vm._s(val.name)+" ")]):_vm._e()])}),0),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane active"},[_c('div',{staticClass:"panel-body"},[_vm._l((_vm.tabs),function(val,key){return _c('div',{key:key,staticClass:"tab-content"},[(key===_vm.active_tab)?_c('div',{staticClass:"row"},_vm._l((val.sections),function(section,s_index){return _c('div',{key:s_index,class:section.width},[_c('div',{staticStyle:{"font-size":"large"}},[_c('strong',[_vm._v(_vm._s(section.name))])]),(section.name)?_c('div',{},[_c('br')]):_vm._e(),_c('div',{staticClass:"row"},_vm._l((section.inputs),function(input,i_index){return _c('div',{key:i_index,class:input.width},[_c(input.type,{tag:"component",attrs:{"name":input.name,"value":input.value,"title":input.title,"error":input.error,"note":input.note,"explanation":input.explanation,"placeholder":input.placeholder,"required":input.required,"readonly":input.readonly,"disabled":input.disabled,"config":input.config},on:{"onInput":_vm.onInput}})],1)}),0),(section.name)?_c('div',{staticClass:"hr-line-dashed"}):_vm._e()])}),0):_vm._e()])}),_c('br'),(_vm.tabs.length > 1)?_c('div',[_c('a',{class:{
                                'btn float-right btn-outline-success':true,
                                'disabled': _vm.active_tab === _vm.tabs.length - 1,
                            },staticStyle:{"margin-left":"5px"},on:{"click":function($event){$event.preventDefault();return _vm.change(_vm.active_tab + 1)}}},[_c('i',{staticClass:"fa fa-angle-right"})]),_c('a',{class:{
                                'btn float-right btn-outline-success': true,
                                'disabled': _vm.active_tab === 0
                            },on:{"click":function($event){$event.preventDefault();return _vm.change( _vm.active_tab - 1 )}}},[_c('i',{staticClass:"fa fa-angle-left"})])]):_vm._e(),_c('br'),_c('br')],2)])])]),_c('div')])}
var staticRenderFns = []

export { render, staticRenderFns }