import Vue from "vue";
import VueRouter from "vue-router";



import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import RegisterSuccess from "@/views/auth/RegisterSuccess";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ForgotPasswordSuccess from "@/views/auth/ForgotPasswordSuccess";
import ResetPassword from "../views/auth/ResetPassword.vue";


import Verify from "@/views/auth/Verify";
import Home from "@/views/layout/Home";
import Pocetna from "@/views/pages/Pocetna";
import Webinari from "@/views/pages/Webinari/Webinari";
import Arhiva from "@/views/pages/Arhiva/Arhiva";
import BodoviComponent from "../views/pages/Bodovi/BodoviComponent";
import Prijave from "@/views/pages/Prijave/Prijave";
import PrijavaDetails from "@/views/pages/Prijave/PrijavaDetails";
import PrijavaCreate from "@/views/pages/Prijave/PrijavaCreate";
import PrijavaEdit from "@/views/pages/Prijave/PrijavaEdit";

/**
 * CUSTOM FORME
 */
import Upitnik15DaniCOVID from "../views/pages/Custom/HKIG/Upitnik15DaniCOVID";
import IskazPeljeskiMost from "../views/pages/Custom/HKIG/IskazPeljeskiMost";
import Ingbiro from "../views/pages/Custom/HKIG/Ingbiro";
import WebinariDani15 from "../views/pages/Custom/HKIG/WebinariDani15";






Vue.use(VueRouter);

const guest = (to, from, next) => {
  console.log( !localStorage.getItem("access_token") )
  if (!localStorage.getItem("access_token")) {
    return next();
  } else {
    return next("/");
  }
};
const auth = (to, from, next) => {
  if (localStorage.getItem("access_token")) {
    return next();
  } else {
    return next("/login");
  }
};

const routes = [

  {
    path: '/',
    component: Home,
    children: [
      {
        path: '/',
        component: Pocetna,
        beforeEnter: auth,
      },
      {
        path: "/login",
        name: "Login",
        beforeEnter: guest,
        component: Login
      },
      {
        path: "/register",
        name: "Register",
        beforeEnter: guest,
        component: Register
      },
      {
        path: "/register-success",
        name: "Register Success",
        beforeEnter: guest,
        component: RegisterSuccess
      },
      {
        path: "/verify/:token",
        name: "Verify",
        beforeEnter: guest,
        props: true,
        component: Verify
      },
      {
        path: "/forgot-password",
        component: ForgotPassword
      },
      {
        path: "/forgot-password-success",
        component: ForgotPasswordSuccess
      },
      {
        path: "/reset-password/:token",
        component: ResetPassword
      },
      {
        path: '/pocetna',
        component: Pocetna,
        beforeEnter: auth,
      },
      {
        path: '/webinari',
        component: Webinari,
        beforeEnter: auth,
      },
      {
        path: '/arhiva',
        component: Arhiva,
        beforeEnter: auth,
      },
      {
        path: '/bodovi',
        component: BodoviComponent,
        beforeEnter: auth,
      },
      {
        path: '/prijave/:skup_id/prijava',
        component: PrijavaCreate,
        beforeEnter: auth,
      },
      {
        path: "/prijave/:skup_id/prijava/:prijava_id",
        component: PrijavaDetails,
        beforeEnter: auth,
      },
      {
        path: "/prijave/:skup_id/prijava/:prijava_id/edit",
        component: PrijavaEdit,
        beforeEnter: auth,
      },
      {
        path: '/prijave',
        component: Prijave,
        beforeEnter: auth,
      },
      {
        path: '/upitnik-covid/:oib',
        component: Upitnik15DaniCOVID
      },
      {
        path: '/iskaz-peljeski-most',
        component: IskazPeljeskiMost,
        beforeEnter: auth,
      },
      {
        path: '/pravni-portal',
        component: Ingbiro,
        beforeEnter: auth,
      },
      {
        path: '/webinari-15-dani',
        component: WebinariDani15,
        beforeEnter: auth,
      }
    ]
  },




];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
